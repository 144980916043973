



import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class PasswordFieldView extends Vue {
  @Prop({ type: String, default: '' }) value!: string
}
