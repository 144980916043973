



import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class RatingFieldView extends Vue {
  @Prop({ type: Number }) value?: number | null

  get displayValue() {
    if (this.value == null) return '0'
    return this.value
  }
}
