import { pathToRegexp, compile, Key } from 'path-to-regexp'

/**
 * Resolve Links with parameters and checks if they are internal or external.
 * @param link
 * @param params
 */
export default function resolveLink(
  link: string,
  params: Record<string, any>
): ResolvedLink {
  try {
    // Check for single-parameter URLs
    if (link.match(/^:\w+$/)) {
      return {
        href: params[link.slice(1)],
        external: true
      }
    }
    let linkPrefix = ''
    if (link.startsWith('http://')) linkPrefix = 'http:'
    if (link.startsWith('https://')) linkPrefix = 'https:'
    // Compile URL
    const url = new URL(
      link,
      `${document.location.protocol}//${document.location.host}`
    )
    const keys: Key[] = []
    pathToRegexp(url.pathname, keys)
    // Extract and normalize params
    const normalizedParams: Record<string, string> = {}
    for (const key of keys) {
      normalizedParams[key.name] =
        params[key.name] == null ? ':' + key.name : params[key.name].toString()
    }
    // Compile the path
    const resolver = compile(link.replace(/^https?:\/\//, '//'), {
      encode: encodeURIComponent
    })
    url.pathname = resolver(normalizedParams)
    // Determine if internal or external
    const external = !!decodeURIComponent(url.pathname).match(/^\/\//)
    return {
      href: decodeURIComponent(url.pathname),
      external
    }
  } catch (e) {
    console.error('Error resolving link', link, e)
    return {
      href: link,
      external: true
    }
  }
}

export interface ResolvedLink {
  href: string
  external: boolean
}
