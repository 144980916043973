import * as monaco from 'monaco-editor'

// moment.js
import lib_moment from '!raw-loader!moment/ts3.1-typings/moment.d.ts'
import lib_momentTimezone from '!raw-loader!moment-timezone/index.d.ts'
import lib_momentTimezone_util from '!raw-loader!moment-timezone/moment-timezone-utils.d.ts'

monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_moment,
  'moment/ts3.1-typings/moment.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_momentTimezone,
  'moment-timezone/index.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_momentTimezone_util,
  'moment-timezone/moment-timezone-utils.d.ts'
)

// lodash
import LODASH_index from '!raw-loader!@types/lodash/index.d.ts'
import LODASH_common from '!raw-loader!@types/lodash/common/common.d.ts'
import LODASH_array from '!raw-loader!@types/lodash/common/array.d.ts'
import LODASH_collection from '!raw-loader!@types/lodash/common/collection.d.ts'
import LODASH_date from '!raw-loader!@types/lodash/common/date.d.ts'
import LODASH_function from '!raw-loader!@types/lodash/common/function.d.ts'
import LODASH_lang from '!raw-loader!@types/lodash/common/lang.d.ts'
import LODASH_math from '!raw-loader!@types/lodash/common/math.d.ts'
import LODASH_number from '!raw-loader!@types/lodash/common/number.d.ts'
import LODASH_object from '!raw-loader!@types/lodash/common/object.d.ts'
import LODASH_seq from '!raw-loader!@types/lodash/common/seq.d.ts'
import LODASH_string from '!raw-loader!@types/lodash/common/string.d.ts'
import LODASH_util from '!raw-loader!@types/lodash/common/util.d.ts'

monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_index,
  '@types/lodash/index.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_common,
  '@types/lodash/common/common.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_array,
  '@types/lodash/common/array.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_collection,
  '@types/lodash/common/collection.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_date,
  '@types/lodash/common/date.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_function,
  '@types/lodash/common/function.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_lang,
  '@types/lodash/common/lang.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_math,
  '@types/lodash/common/math.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_number,
  '@types/lodash/common/number.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_object,
  '@types/lodash/common/object.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_seq,
  '@types/lodash/common/seq.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_string,
  '@types/lodash/common/string.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  LODASH_util,
  '@types/lodash/common/util.d.ts'
)

// numeral
import lib_numeral from '!raw-loader!@types/numeral/index.d.ts'

monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_numeral,
  '@types/numeral/index.d.ts'
)

// axios
import lib_axios from '!raw-loader!axios/index.d.ts'

monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_axios
    .replace(
      'declare const Axios: AxiosStatic;',
      'declare var axios: AxiosStatic;'
    )
    .replace('export default Axios;', '')
    .replace(/^export /gm, ''),
  'axios/index.d.ts'
)

// Chance
import lib_chance from '!raw-loader!@types/chance/index.d.ts'

monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_chance,
  '@types/chance/index.d.ts'
)

// Fetch
/*
import lib_fetch from '!raw-loader!@types/node-fetch/index.d.ts'
import lib_fetch_ext from '!raw-loader!@types/node-fetch/externals.d.ts'

monaco.languages.typescript.javascriptDefaults.addExtraLib(lib_fetch, '@types/node-fetch/index.d.ts')
monaco.languages.typescript.javascriptDefaults.addExtraLib(lib_fetch_ext, '@types/node-fetch/externals.d.ts')
*/
// Cheerio
import lib_cheerio from '!raw-loader!cheerio/types/index.d.ts'
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_cheerio + '\nexport as namespace cheerio',
  'cheerio/types/index.d.ts'
)

// Codeless static
import lib_codeless_database from '!raw-loader!./codeless_database.d.ts'
import lib_codeless_util from '!raw-loader!./codeless_util.d.ts'
import lib_codeless_libs from '!raw-loader!./codeless_libs.d.ts'

monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_codeless_database,
  '@simplexcl/codeless_database/index.d.ts'
)
monaco.languages.typescript.javascriptDefaults.addExtraLib(
  lib_codeless_util,
  '@simplexcl/codeless_util/index.d.ts'
)
