import es from './es.toml'
import en from './en.toml'

const locales: Record<string, any> = {
  es,
  en
}

export default locales

export function getAvailableLocales() {
  return Object.keys(locales).map((key) => ({
    key,
    displayName: locales[key].meta.display_name
  }))
}
