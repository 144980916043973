









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Loading extends Vue {
  @Prop({ type: String }) color?: string

  @Prop({ type: String }) skeleton?: string

  get rippleColor() {
    return this.color || this.$vuetify.theme.currentTheme.primary
  }
}
