import { ComponentType, Block } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import EditorLoading from './FullViewEdit/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const blocks = {
  name: 'block',
  namespace: 'blocks',
  title: $t('blocks_index.title'),
  titleSingle: $t('blocks_index.titleSingle'),
  description: $t('blocks_index.description'),
  icon: 'polymer',
  color: 'green',
  overview:
    $t('blocks_index.overview'),
  queryName: 'blocks',
  queryFields: `{
    _id
    name
    title
    type
  }`,
  searchable: true,
  titleIsMale: true,

  transformResult: (block) => ({
    ...block,
    name: block.name,
    title: block.title,
    subtitle: {
      html: $t('blocks_index.subtitle.html'),
      text: $t('blocks_index.subtitle.text')
    }[block.type!],
    icon: {
      html: 'polymer',
      text: 'line_style'
    }[block.type!],
    href: `blocks/${block._id}`
  }),

  viewSkeleton: 'article',

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
  /*fullViewEdit: () =>
    ({
      component: import('./FullViewEdit/FullViewEdit.vue').then(
        (e) => e.default
      ),
      loading: EditorLoading,
      error: ComponentError
    } as unknown as Vue.AsyncComponent),
  */
  view: () => asyncImport(import('./View.vue'))
} as ComponentType<Block>
