






















import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  components: {
  },
})
export default class TellusButton extends Vue {
  @Prop({ type: String, default: 'primary' }) buttonColor!: string
  get environment() {
    return this.$store.state.environment?.environment
  }
  get unreadMessageCountByClient() {
    return this.$store.state.app.unreadMessageCountByClient
  }
  openMessages() {
    this.$store.commit('app/updateOpenMessage', true)
  }
}
