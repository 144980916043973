















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import delay from '@/utils/async/delay'
import SignatureHelper from '@/components/fields/signature/SignatureHelper'

declare global {
  interface Window {
    Signature: any
  }
}

@Component({
  components: {
    Loading
  }
})
export default class SignatureCompatCaptureModal extends Vue {
  @Prop({ type: Boolean, required: true }) open!: boolean
  @Prop({ type: Boolean }) captured?: boolean
  @Prop({ type: String }) signatureImage?: string
  @Prop({ type: String, default: ' ' }) who!: string
  @Prop({ type: String, default: 'Registro de Firma' }) reason!: string

  drawing = false
  manuallyCapturing = false

  get modalOpen() {
    return this.open
  }

  set modalOpen(v: boolean) {
    this.$emit('update:open', v)
  }

  @Watch('open')
  initialize(v: boolean) {
    if ((this.captured && !this.manuallyCapturing) || !v) return

    this.$nextTick(() => {
      const canvas = this.$refs.canvas as HTMLCanvasElement
      if (canvas.dataset.initialized) return
      const ctx = canvas.getContext('2d')!

      canvas.addEventListener('pointerdown', (e) => {
        e.preventDefault()
        this.drawing = true
        console.log(e)
        ctx.beginPath()
        ctx.moveTo(e.offsetX, e.offsetY)
        ctx.lineWidth = 2
      })
      canvas.addEventListener('pointermove', (e) => {
        if (!this.drawing) return
        const opacity =
          e.pointerType === 'mouse' ? 1 : Math.min(0.5 + e.pressure, 1)
        ctx.strokeStyle = `rgba(0,0,0,${opacity})`
        ctx.lineTo(e.offsetX, e.offsetY)
        ctx.stroke()
        ctx.beginPath()
        ctx.moveTo(e.offsetX, e.offsetY)
      })
      canvas.addEventListener('pointerup', (e) => {
        this.drawing = false
      })
      canvas.addEventListener('pointercancel', (e) => {
        this.drawing = false
      })

      canvas.dataset.initialized = '1'
    })
  }

  captureAgain() {
    this.manuallyCapturing = true
    this.initialize(true)
  }

  clearCanvas() {
    const canvas = this.$refs.canvas as HTMLCanvasElement
    const ctx = canvas.getContext('2d')!
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  save() {
    const canvas = this.$refs.canvas as HTMLCanvasElement
    this.$emit('save', {
      image: canvas.toDataURL(),
      ink: ''
    })
    this.modalOpen = false
    this.manuallyCapturing = false
    this.clearCanvas()
  }

  cancel() {
    this.modalOpen = false
    this.manuallyCapturing = false
  }
}
