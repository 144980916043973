




























import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormSchema } from '@/models'
import Loading from '@/components/Loading.vue'
import gql from 'graphql-tag'
import _capitalize from 'lodash/capitalize'
import Form from './Form.vue'

@Component({
  components: {
    Form,
    Loading
  },
  apollo: {
    queryParams: {
      query: gql`
        query getParams($name: ID) {
          queryParams: params(name: $name, mutation: true) {
            name
            result
            basicResultQuery
            params
          }
        }
      `,
      variables() {
        return {
          name: this.mutation
        }
      }
    }
  }
})
export default class MutationForm extends Vue {
  /** Mutation Name */
  @Prop({ type: String, required: true }) mutation!: string
  /** Form Schema */
  @Prop({ type: Object, required: true }) schema!: FormSchema
  /** Environment Variables */
  @Prop({ type: Object }) environmentVariables?: Record<string, string>
  /** Mutation constants */
  @Prop({ type: Object, default: () => ({}) }) constants!: Record<
    string,
    string
  >

  /** Save Button Text */
  @Prop({ type: String, default: 'Enviar' }) submitButtonText!: string
  /** Save Button Icon */
  @Prop({ type: String, default: 'send' }) submitButtonIcon!: string
  /** Save Button Color */
  @Prop({ type: String, default: 'primary' }) submitButtonColor!: string
  /** Disable (hide) the reset button */
  @Prop({ type: Boolean, default: false }) noReset!: boolean
  /** Disabled state */
  @Prop({ type: Boolean }) disabled!: boolean
  /** Query sub-selection */
  @Prop({ type: String, default: '' }) subSelection?: string

  /** Saved values */
  @Prop({ type: Object, default: () => ({}) }) savedValue!: Record<string, any>

  /** Server validation errors */
  validationErrors: Record<string, string[]> = {}
  /** GraphQL Query Parameters */
  queryParams?: any
  /** Busy state */
  busy = false

  /** Get mutation definition */
  get mutationBody() {
    const fieldKeys = Object.keys({ ...this.schema, ...this.constants })
    const params = fieldKeys.map((f) => `${f}: $${f}`).join(', ')
    const args = fieldKeys
      .map(
        (f) =>
          `$${f}: ${
            this.schema[f]
              ? this.schema[f].__graphQLType || _capitalize(this.schema[f].type)
              : _capitalize(typeof this.constants[f])
          }`
      )
      .join(', ')
    const subsel =
      this.subSelection ||
      (this.queryParams && this.queryParams.basicResultQuery) ||
      ''
    const form = this.$refs.form as any
    return `
    mutation ${this.mutation} (${args}) {
      result: ${this.mutation} (${params}) ${subsel}
    }
    `
  }

  get primaryColor() {
    return (this.$vuetify.theme.currentTheme.primary as any).base
  }

  get schemaWithoutConstants() {
    const schemaCopy = { ...this.schema }
    Object.keys(this.constants).forEach((c) => {
      delete schemaCopy[c]
    })
    return schemaCopy
  }

  async submit() {
    const form = this.$refs.form as any
    if (form.busy || !form.valid) return

    this.$emit('submit', form.value)
    this.busy = true

    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql(this.mutationBody, ''),
        variables: { ...this.constants, ...form.value }
      })
      form.undo()
      this.$emit('success', data.result)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
      // TODO: Handle other kinds of errors
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err: any) => {
          if (err.error === 'validationError') {
            this.$emit('validationErrors', err.validationErrors)
            this.$set(this, 'validationErrors', err.validationErrors)
          }
        })
      }
    } finally {
      this.busy = false
    }
  }
}
