

















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { FormField } from '@/models'
import { Field as DateField } from '../date'
import moment from '@/plugins/moment'

interface DateRangeFieldOptions {
  showRelativeOptions?: boolean,
  nextDays?: number,
  dateMenu?: boolean
}

@Component({
  components: {
    DateField
  }
})
export default class DateRangeField extends Vue {
  /** Current Value */
  @Prop({ type: String }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({type: Array, required: false}) rules!:Record<string, any>[]
  /** Rules disabled */
  @Prop({type: Boolean, default: false}) norules!: boolean
  /** Icon disabled */
  @Prop({type: Boolean, default: true}) noicon!: boolean
  /** outlined prop */
  @Prop({type: Boolean, default: true}) outlined!: boolean
  /** dense prop */
  @Prop({type: Boolean, default: true}) dense!: boolean

  datePicker: boolean = false
  dateModel: any = []
  overlay: boolean = false
  dateMenu: boolean = false
  item: any

  @Watch('value')
  clearSelect(value?: string | null) {
    if (value == null) {
      ;(this.$refs.select as HTMLFormElement).reset()
    }
  }

  get getIcon(){
    if(this.noicon) return ''
    return 'history'
  }

  get validationRules() {
    if(this.norules) return []

    const rules = this.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('daterange_field.script.validationRules.required'))
    }
    return rules
  }

  saveDateModel(value: string[]) {
    if (value[1]) {
      if (moment(value[1]).isAfter(value[0])) {
        this.dateModel = [value[0], value[1]]
      } else {
        this.dateModel = [value[1], value[0]]
      }
    } else {
      this.dateModel = [value[0], value[0]]
    }
    const fromDate = moment(this.dateModel[0]).startOf('day').toISOString()
    const toDate = moment(this.dateModel[1]).endOf('day').toISOString()
    this.$emit('input', `${fromDate}::${toDate}`)
    this.datePicker = false
    this.overlay = false
  }

  canceDateModel() {
    ;(this.$refs.select as HTMLFormElement).reset()
    this.emitNull()
    this.datePicker = false
    this.overlay = false
  }

  get showDate() {
    return [
      moment(this.dateModel[0]).format('DD-MM-YYYY').toString(),
      moment(this.dateModel[1]).format('DD-MM-YYYY').toString()
    ].join(' ~ ')
  }

  emitNull() {
    this.dateModel = []
    this.$emit('input', null)
  }

  relativeItems = [
    { value: 'day', text: this.$t('daterange_field.script.relativeItems.day') },
    { value: 'yesterday', text: this.$t('daterange_field.script.relativeItems.yesterday')  },
    { value: 'week', text: this.$t('daterange_field.script.relativeItems.week')  },
    { value: 'month', text: this.$t('daterange_field.script.relativeItems.month')  },
    { value: 'custom', text: this.$t('daterange_field.script.relativeItems.custom')  }
  ]

  _relativeValue = ''

  get relativeValue() {
    return this._relativeValue
  }
  set relativeValue(value: string) {
    this._relativeValue = value

    let fromDate
    let toDate
    switch (value) {
      case 'day':
        fromDate = moment().startOf('day').toISOString()
        toDate = moment().endOf('day').toISOString()
        this.$emit('input', `${fromDate}::${toDate}`)
        break
      case 'yesterday':
        fromDate = moment().subtract(1, 'day').startOf('day').toISOString()
        toDate = moment().subtract(1, 'day').endOf('day').toISOString()
        this.$emit('input', `${fromDate}::${toDate}`)
        break
      case 'week':
        fromDate = moment().subtract(7, 'day').startOf('day').toISOString()
        toDate = moment().subtract(1, 'day').endOf('day').toISOString()
        this.$emit('input', `${fromDate}::${toDate}`)
        break
      case 'month':
        fromDate = moment().subtract(30, 'day').startOf('day').toISOString()
        toDate = moment().subtract(1, 'day').endOf('day').toISOString()
        this.$emit('input', `${fromDate}::${toDate}`)
        break
      case 'custom':
        this.datePicker = true
        this.dateModel = []
        this.overlay = true
        break
    }
  }

  /** Additional field options */
  get fieldOptions(): DateRangeFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get fromDate() {
    return (this.value && this.value.split('::')[0]) || null
  }

  set fromDate(value: string | null) {
    this.$emit(
      'input',
      `${moment(value).startOf('day').toISOString() || ''}::${
        this.toDate || ''
      }`
    )
  }

  get toDate() {
    return (this.value && this.value.split('::')[1]) || null
  }

  set toDate(value: string | null) {
    this.$emit(
      'input',
      `${this.fromDate || ''}::${
        moment(value).endOf('day').toISOString() || ''
      }`
    )
  }
}
