



import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class BooleanFieldView extends Vue {
  @Prop({ type: Boolean, default: false }) value!: string
}
