import { ComponentType, Collection } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const collections = {
  name: 'collection',
  namespace: 'collections',
  title: $t('collections_index.title'),
  titleSingle: $t('collections_index.titleSingle'),
  description: $t('collections_index.description'),
  icon: 'storage',

  queryName: 'collections',
  queryFields: `{
    _id
    environmentId
    type
    name
  }`,
  searchable: true,
  hidden: true,

  transformResult: (collection) => ({
    ...collection,
    name: collection.name,
    title: collection.name,
    subtitle: collection._id,
    icon: collection.type === 'vector' ? 'hub' : 'storage',
    labels:
      collection.type === 'vector'
        ? [{ text: 'AI', color: 'primary', textColor: 'white' }]
        : [],
    href: `/${collection.environmentId}/collections/${collection._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Collection>
