import { ComponentType, View } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}
export const views = {
  name: 'view',
  namespace: 'views',
  title:  $t('views_index.title'),
  titleSingle: $t('views_index.titleSingle'),
  description: $t('views_index.description'),
  icon: 'dashboard',

  queryName: 'views',
  queryFields: `{
    _id
    environmentId
    name
    path
  }`,
  searchable: true,
  hidden: true,

  transformResult: (view) => ({
    ...view,
    title: view.name,
    subtitle: view.path,
    href: `/${view.environmentId}/design/${view._id}`
  }),

  create: () => asyncImport(import('./Create.vue'))
} as ComponentType<View>
