import { RootState } from '@/store/index'
import { Module } from 'vuex'
import { Environment } from '@/models'
import isAdminDomain from '@/utils/client/isAdminDomain'

export interface AppState {
  darkMode: boolean
  editLinks: boolean
  recents: Partial<Environment>[],
  openNotifications: boolean,
  notificationCount: number, 
  openMessages: boolean,
  unreadMessageCountByClient: number
}

/**
 * Authentication Module - Stores info about the currently logged in user.
 */
const AppModule: Module<AppState, RootState> = {
  namespaced: true,
  state: {
    darkMode:
      window.localStorage.darkMode == null
        ? isAdminDomain()
        : !!window.localStorage.darkMode,
    editLinks: !!window.localStorage.codelessEditLinks,
    recents: JSON.parse(localStorage.codelessRecents || '[]'),
    openNotifications: false,
    notificationCount: 0,
    openMessages: false, 
    unreadMessageCountByClient: 0
  },
  mutations: {
    /**
     * Toggle Dark Mode
     * @param state - Current app state
     */
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode
      if (state.darkMode) {
        localStorage.darkMode = 1
      } else {
        delete localStorage.darkMode
      }
    },
    /**
     * Toggle Edit Links
     * @param state - Current app state
     */
    toggleEditLinks(state) {
      state.editLinks = !state.editLinks
      if (state.editLinks) {
        localStorage.codelessEditLinks = 1
      } else {
        delete localStorage.codelessEditLinks
      }
    },
    /**
     * Add an environment to the recents list.
     */
    addRecent(state, env: Partial<Environment>) {
      // Find and remove current entry
      const currentIndex = state.recents.findIndex((e) => e._id === env._id)
      if (currentIndex >= 0) {
        state.recents.splice(currentIndex, 1)
      }
      // Add new entry
      state.recents.unshift(env)
      // Limit to 5 entries
      if (state.recents.length > 5) {
        state.recents = state.recents.slice(0, 5)
      }
      localStorage.codelessRecents = JSON.stringify(state.recents)
    },
    /**
     * Update Open Notifications
     * @param state - Current app state
     */
    updateNotifications(state, value) {
      state.openNotifications = value
    },
    /**
     * Update Notifications Count
     * @param state - Current app state
     */
    updateNotificationCount(state, value) {
      state.notificationCount = value
    },
    /**
     * Update Open Messages
     * @param state - Current app state
     */
    updateOpenMessage(state, value) {
      state.openMessages = value
    },
    /**
     * Update Unread Message Count By Client
     * @param state - Current app state
     */
    updateUnreadMessageCountByClient(state, value) {
      state.unreadMessageCountByClient = value
    }
  },
  getters: {},
  actions: {}
}

export default AppModule
