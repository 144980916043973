







































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Environment, EnvironmentUser, View } from '@/models'
import Loading from '@/components/Loading.vue'
import ViewComponents from './ViewComponents.vue'
import Navbar from './Navbar.vue'
import gql from 'graphql-tag'

@Component({
  components: {
    Navbar,
    ViewComponents,
    Loading,
  },
  apollo: {
    view: {
      query: gql`
        query getView($viewId: ID, $environmentId: ID) {
          view: view(viewId: $viewId, environmentId: $environmentId) {
            _id
            name
            environmentId
            title
            path
            roles
            intercom
            titleColor
            titleBackground
            hideBorders
            backButton
            printButton
            backLink
            useCustomCss
            customCss
            items {
              sizeSmall
              sizeMedium
              sizeLarge
              type
              formId
              tableId
              indicatorId
              chartId
              buttonId
              blockId
              vueBlockId
              fullSize
              subItems
              content
              helpBlockId
              titleColor
              titleBackground
              hideBorders
              forceBorders
              viewType
              tabName
              hidePrint
              cssClass
              touchless
            }
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environment._id,
          viewId: this.viewId,
        }
      },
      skip() {
        return !this.viewId
      },
    },
    $subscribe: {
      environmentUpdated: {
        query: gql`
          subscription onEnvironmentUpdated($environmentId: ID) {
            environmentUpdated(environmentId: $environmentId)
          }
        `,
        variables() {
          return {
            environmentId: this.environment._id,
          }
        },
        async result({ data }: any) {
          await this.$apollo.provider.defaultClient.queryManager.reFetchObservableQueries()
        },
      },
    },
  },
})
export default class EnvironmentView extends Vue {
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<
    string,
    string
  >
  @Prop({ type: String }) viewId!: string
  @Prop({ type: String, default: '' }) path!: string
  @Prop({ type: Boolean, default: false }) sidebar!: boolean
  @Prop({ type: Boolean, default: false }) sidebarOpen!: boolean
  @Prop({ type: [Number, String] }) notificationCount?: number | string
  @Prop({ type: [Number, String] }) unreadMessageCount?: number | string
  @Prop({ type: [Number, String], default: 0 }) unreadMessageCountByClient?:
    | number
    | string
  @Prop({ type: Boolean, default: false }) disableNavbar!: boolean
  @Prop({ type: Boolean, default: false }) hideLoading!: boolean
  @Prop({ type: Boolean, default: false }) noCustomCSS!: boolean
  @Prop({ type: Boolean, default: false }) noPadding!: boolean

  view: View | null = null

  @Watch('viewId')
  updateView(newViewId: string) {
    if (!newViewId) this.view = null
    else return this.$apollo.queries.view.refetch()
  }
}
