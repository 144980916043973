/** Add a global declaration for window.env */
declare global {
  interface Window {
    env: Record<string, any>
  }
}

/**
 * Gets an environment variable from either the compiled environment or the
 * runtime environment.
 * @param key Key to get
 * @returns Value of the key
 */
export default function getEnv(key: string) {
  const merged = { ...process.env, ...window.env }
  return merged[key]
}
