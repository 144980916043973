import getEnv from '@/plugins/getEnv'

/**
 * Returns true if the user is currently in the admin domain.
 */
export default function isAdminDomain() {
  if (getEnv('VUE_APP_IS_DESKTOP')) {
    return !!window.codelessDesktop
  }
  return (
    document.location.hostname === getEnv('VUE_APP_ADMIN_DOMAIN') ||
    (getEnv('VUE_APP_ENV') === 'local' &&
      ['localhost', '127.0.0.1', '[::1]'].includes(document.location.hostname))
  )
}
