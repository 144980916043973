















import { Component, Vue, Prop } from 'vue-property-decorator'
import SelectModal from './SelectModal.vue'

@Component({
  components: {
    SelectModal
  }
})
export default class FileFieldView extends Vue {
  @Prop({ type: String }) value?: string

  viewModalOpen = false
}
