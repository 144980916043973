var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"login"},[_c('StatusBar',{attrs:{"statusStyle":"default"}}),_c('v-main',{staticClass:"background",class:{ background__logo: !_vm.authenticating }},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[(_vm.authenticating || _vm.$apollo.loading)?_c('v-flex',{staticClass:"login-container authenticating",attrs:{"xs12":""}},[_c('div',[_c('codeless-loading',{attrs:{"text":"codelex"}})],1)]):(_vm.domain && !_vm.environment)?_c('v-card',{staticClass:"login-card background-2 text-center",staticStyle:{"border-color":"var(--v-background-5-base) !important"},attrs:{"outlined":"","tile":"","elevation":"14","width":"560"}},[_c('v-card-text',{staticClass:"logged-in-greet text-center"},[_c('div',[_c('v-icon',{attrs:{"size":"96"}},[_vm._v("error")])],1),_c('br'),_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('NewLogin.invalidUrl')))])])],1):_c('v-card',{staticClass:"login-card background-2 text-center",staticStyle:{"border-color":"var(--v-background-5-base) !important"},attrs:{"outlined":"","tile":"","elevation":"0","width":"560"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4",attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('NewLogin.login')))]),_c('div',{staticClass:"logo"},[_c('v-img',{attrs:{"src":require("@/assets/logo-codelex.svg"),"max-width":"480","max-height":"96","contain":""}})],1),(!_vm.loggedIn)?_c('v-card-text',{staticClass:"text-start"},[(!_vm.forgot)?_c('MutationForm',{ref:"loginForm",attrs:{"mutation":"loginWithPassword","submitButtonText":_vm.$t('NewLogin.mutationForm.submitBtn'),"submitButtonIcon":"vpn_key","schema":{
                    email: {
                      label: _vm.$t('NewLogin.schema.email.label'),
                      type: 'string',
                      fieldType: 'email',
                      newDesign: true,
                      fieldOptions: {
                        icon: 'person'
                      }
                    },
                    password: {
                      label: _vm.$t('NewLogin.schema.password.label'),
                      type: 'string',
                      fieldType: 'password',
                      newDesign: true,
                      fieldOptions: {
                        icon: 'lock'
                      }
                    }
                  },"no-reset":""},on:{"success":_vm.loginSuccess}},[(!_vm.forgot)?_c('v-btn',{staticStyle:{"color":"var(--v-background-4-base) !important","border-color":"var(--v-background-4-base)"},attrs:{"slot":"left-buttons","text":"","outlined":"","tile":"","color":"primary"},on:{"click":function($event){_vm.forgot = true}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('NewLogin.mutationForm.forgotPassBtn')))]):_vm._e()],1):_c('MutationForm',{ref:"forgotForm",attrs:{"mutation":"forgotPassword","subSelection":" ","schema":{
                    email: {
                      label: 'E-Mail',
                      type: 'string',
                      fieldType: 'email',
                      fieldOptions: {
                        icon: 'person'
                      }
                    }
                  },"no-reset":""},on:{"success":_vm.forgotSuccess}},[_c('v-btn',{attrs:{"slot":"right-buttons","text":"","tile":"","color":"primary"},on:{"click":function($event){_vm.forgot = false}},slot:"right-buttons"},[_vm._v(_vm._s(_vm.$t('NewLogin.mutationForm.loginBtn')))])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }