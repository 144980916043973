export interface Experiment {
  id: string
  name: string
  description: string
}

export const availableExperiments: Experiment[] = [
  {
    id: 'vue-properties-window',
    name: 'Editor de propiedades (Vue)',
    description:
      'Muestra las propiedades del elemento seleccionado en el editor de plantillas'
  },
  {
    id: 'collection-editor-ai',
    name: 'Inteligencia Artificial en editor de colecciones',
    description:
      'Herramientas de IA para ayudar a crear colecciones y datos de prueba'
  },
  {
    id: 'ai-assistant',
    name: 'Asistente Inteligente',
    description: 'Asistente para diseñar procesos y vistas'
  }
]
