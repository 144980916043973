





















import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import numeral from '@/plugins/numeral'
import { textHeights } from 'ol/render/canvas'

interface RUTFieldOptions {
  placeholder?: string
  icon?: string
  rules?: any[]
  norules?: boolean
  dense?: boolean
  outlined?: boolean
}

@Component({})
export default class RUTField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({ type: Array, required: false }) rules!: Record<string, any>[]
  /** Rules disabled */
  @Prop({ type: Boolean, default: false }) norules!: boolean
  /** Icon disabled */
  @Prop({ type: Boolean, default: false }) icon!: boolean
  /** outlined prop */
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  /** dense prop */
  @Prop({ type: Boolean, default: true }) dense!: boolean

  get getIcon() {
    if (this.icon || this.fieldOptions.icon) {
      return this.fieldOptions.icon || 'person'
    }
    return ''
  }
  /** Validation Rules */
  get validationRules() {
    if (this.norules || this.fieldOptions.norules) return []

    const rules = this.fieldOptions.rules || this.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || 'Campo requerido.')
    }
    // RUT Validation
    rules.push((v: string) => this.validateRut(v))
    return rules
  }

  /** Additional field options */
  get fieldOptions(): RUTFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }
  clear() {
    this.$emit('input', '')
  }
  /** Input event handler */
  handleInput(rut: string) {
    // If the RUT is empty, emit an empty value
    if (!rut) return this.$emit('input', '')
    if (rut.length < 3) return this.$emit('input', rut)
    // Remove any characters that are not digits or 'k' or 'K'
    const numbers = rut.replace(/[^\dkK]/g, '')
    // Split the RUT into individual characters
    const parts = numbers.split('')
    // If there are no parts, emit an empty value
    if (!parts.length) return this.$emit('input', '')
    const last = parts.pop()
    this.$emit('input', numeral(parts.join('')).format('0,0') + '-' + last)
  }

  /** Verificar RUT y DV */
  validateRut(input: string) {
    if (!input) return true
    // Verificar formato
    if (!/^\d{1,3}\.?\d{3}\.?\d{3}-?[\w\d]$/.test(input)) return 'RUT Inválido'
    // Dividir RUT y Digito Verificador
    const rutParts = input.split('-')
    const rut = parseInt(rutParts[0].replace(/\./g, ''), 10)
    let dv: string | number = rutParts[1]
    if (!dv) return 'RUT Inválido'
    // k = 10
    if (dv.toLowerCase() === 'k') dv = 10
    // 0 = 11
    if (dv === '0') dv = 11
    dv = parseInt(dv.toString(), 10)
    // Serie para validad DV
    const serie = [2, 3, 4, 5, 6, 7]
    // Se toman los digitos individuales del RUT de derecha a izquierda
    const prod = rut
      .toString()
      .split('')
      .reverse()
      // Se multiplica cada digito por su correspondiente en la serie definida anteriormente (2,3,4,5,6,7), si se llega al final de la serie se repite la misma
      .map((d, i) => parseInt(d, 10) * serie[i % serie.length])
      // Se suman todos los productos
      .reduce((a, b) => a + b)
    // Se divide entre 11 y se toma el residuo
    const mod = prod % 11
    // Se resta el modulo de 11 para tomar el DV válido
    const dvValido = 11 - mod
    // Se compara con el DV introducido
    if (dv !== dvValido) return 'RUT Inválido'

    return true
  }
}
