import { editor } from 'monaco-editor'

export const codelexDark: editor.IStandaloneThemeData = {
  inherit: true,
  base: 'vs-dark',
  colors: {
    'editor.foreground': '#FAFCFF',
    'editor.background': '#131532',
    'minimap.background': '#131532',
    'editorGutter.background': '#131532',
    'editorMarkerNavigation.background': '#131532',
    'peekViewEditor.background': '#131532',
    'sideBar.background': '#131532',
    'editorOverviewRuler.border': '#131532',
    'editorWidget.background': '#1A1C3D',
    'editor.lineHighlightBorder': '#2B3457',
    'scrollbarSlider.activeBackground': '#2B3457',
    'scrollbarSlider.background': '#2B3457'
  },
  rules: [
    //@ts-ignore
    { background: '#131532' }
  ]
}
