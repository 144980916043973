export default {
  youtube(d: any) {
    if (d.type !== 'textDirective') return false
    // @ts-ignore
    this.tag('<iframe class="youtube-md" width="560" height="315"')
    if (d.attributes) {
      let youtubeId =
        d.attributes.src.split('www.youtube.com/watch?v=')[1] ||
        d.attributes.src.split('youtu.be/')[1] ||
        d.attributes.src.split('www.youtube.com/embed/')[1]
      // @ts-ignore
      this.tag(
        // @ts-ignore
        ' src="https://www.youtube.com/embed/' + this.encode(youtubeId) + '"'
      )
    }
    // @ts-ignore
    this.tag(' allowfullscreen="true" frameborder="0">')
    // @ts-ignore
    this.raw(d.label || '')
    // @ts-ignore
    this.tag('</iframe>')
  },
  iframe(d: any) {
    if (d.type !== 'textDirective') return false
    // @ts-ignore
    this.tag('<iframe class="iframe-md" ')
    if (d.attributes) {
      // @ts-ignore
      this.tag(
        `width="${d.attributes.width || 560}" height="${
          d.attributes.height || 315
          // @ts-ignore
        }" src="${this.encode(d.attributes.src)}" class="${this.encode(
          d.attributes.class || ''
        )}"`
      )
    }
    // @ts-ignore
    this.tag(' frameborder="0">')
    // @ts-ignore
    this.raw(d.label || '')
    // @ts-ignore
    this.tag('</iframe>')
  },
  img(d: any) {
    if (d.type !== 'textDirective') return false
    // @ts-ignore
    this.tag('<img class="img-md" ')
    if (d.attributes) {
      // @ts-ignore
      this.tag(
        // @ts-ignore
        `src="${this.encode(d.attributes.src || '')}" alt="${this.encode(
          d.attributes.alt || ''
          // @ts-ignore
        )}" title="${this.encode(d.attributes.title || '')}" style="width:${
          d.attributes.width || 'auto'
          // @ts-ignore
        };height:${this.encode(d.attributes.height || 'auto')};${this.encode(
          d.attributes.style || ''
        )}"`
      )
    }
    // @ts-ignore
    this.tag('>')
  }
}
