import { ComponentType, Agent } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const agents = {
  name: 'agent',
  namespace: 'agents',
  title: $t('agents.title'),
  titleSingle: $t('agents.titleSingle'),
  description: $t('agents.description'),
  icon: 'assistant',
  overview: $t('agents.overview'),
  queryName: 'agents',
  queryFields: `{
    _id
    name
    displayName
  }`,
  color: 'amber',
  searchable: true,
  hidden: true,
  titleIsMale: true,

  transformResult: (agent) => ({
    ...agent,
    name: agent.name,
    title: agent.displayName,
    subtitle: agent.name
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Agent>
