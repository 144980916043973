import {
  CollectionData,
  ComponentType,
  FormField,
  Table,
  TableFieldType
} from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}




export const tables = {
  name: 'table',
  namespace: 'tables',
  title: $t('tables_index.title'),
  titleSingle: $t('tables_index.titleSingle'),
  titleIsMale: false,
  description: $t('tables_index.description'),
  icon: 'table_view',
  tabIcon: 'table_chart',
  color: 'pink',
  overview: $t('tables_index.overview'),
  queryName: 'tables',
  queryFields: `{
    _id
    name
    title
  }`,
  searchable: true,

  transformResult: (table) => ({
    ...table,
    name: table.name,
    title: table.title,
    subtitle: table.name,
    href: `tables/${table._id}`
  }),

  viewSkeleton: 'table',

  create: () => asyncImport(import('./Create.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Table>

export interface ViewTableRow {
  id: string
  raw: CollectionData
  cols: ViewTableCell[]
}

export interface ViewTableCell {
  key: string
  type: TableFieldType
  options?: Record<string, any>
  width?: number
  class?: number
  field?: FormField
  data: any
}
