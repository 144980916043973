import { PushNotificationProvider } from '.'
import urlBase64ToUint8Array from '@/utils/client/urlBase64ToUint8Array'
import getEnv from '@/plugins/getEnv'
import i18n from '@/plugins/i18n'

export default class WebPushProvider implements PushNotificationProvider {
  serviceWorker: ServiceWorkerRegistration | null = null

  $t(localePath: string) {
    return i18n.t(localePath).toString()
  }

  async getState() {
    if ('serviceWorker' in navigator) await this.registerServiceWorker()
    // Check if browser supports push notifications and they aren't denied.
    const available =
      getEnv('VUE_APP_PUSH_PUBLIC_KEY') &&
      'PushManager' in window &&
      Notification.permission !== 'denied'
    let enabled = false
    // Check if notifications are granted and a push subscription exists.
    if (available) {
      enabled =
        Notification.permission === 'granted' &&
        !!(await this.serviceWorker!.pushManager.getSubscription())
    }
    return { available, enabled }
  }

  async subscribe() {
    const { available, enabled } = await this.getState()
    if (!available || enabled) return
    await this.requestNotificationPermission()
    return this.serviceWorker!.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        getEnv('VUE_APP_PUSH_PUBLIC_KEY')!
      ),
    })
  }

  async unsubscribe() {
    const { available, enabled } = await this.getState()
    if (!available || !enabled) return
    const sub = await this.serviceWorker!.pushManager.getSubscription()
    if (sub && (await sub.unsubscribe())) {
      return sub
    }
  }

  async registerServiceWorker() {
    if (this.serviceWorker) return this.serviceWorker
    try {
      this.serviceWorker = await navigator.serviceWorker.register(
        '/serviceworker.js'
      )
      return this.serviceWorker
    } catch (e) {
      console.error(e)
    }
  }

  requestNotificationPermission() {
    const t = this.$t
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (
        result
      ) {
        if (result !== 'granted')
          reject(new Error(t('pushNotifications.error')))
        else resolve(result)
      })
      if (permissionResult) {
        permissionResult.then(resolve).catch(reject)
      }
    })
  }
}
