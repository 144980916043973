import { RootState } from '@/store/index'
import { Module } from 'vuex'
import { Experiment, availableExperiments } from '@/experiments'

export interface ExperimentsState {
  availableExperiments: Experiment[]
  active: Record<string, boolean>
}

const defaultActive: Record<string, boolean> = {}
availableExperiments.forEach((experiment) => {
  defaultActive[experiment.id] = false
})

const experiments: Module<ExperimentsState, RootState> = {
  namespaced: true,
  state: {
    availableExperiments,
    active: {
      ...defaultActive,
      ...JSON.parse(localStorage.getItem('cls_experiments') || '{}')
    }
  },
  mutations: {
    activate(state, experimentId: string) {
      state.active[experimentId] = true
      localStorage.setItem('cls_experiments', JSON.stringify(state.active))
    },
    deactivate(state, experimentId: string) {
      state.active[experimentId] = false
      localStorage.setItem('cls_experiments', JSON.stringify(state.active))
    },
    toggle(state, experimentId: string) {
      state.active[experimentId] = !state.active[experimentId]
      localStorage.setItem('cls_experiments', JSON.stringify(state.active))
    }
  },
  getters: {
    hasActiveExperiments(state) {
      return Object.values(state.active).some(Boolean)
    }
  }
}

export default experiments
