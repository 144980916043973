import { ComponentType, Endpoint, EndpointType } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const endpoints = {
  name: 'endpoint',
  namespace: 'endpoints',
  title: $t('endp_index.title'),
  titleSingle: $t('endp_index.titleSingle'),
  description: $t('endp_index.description'),
  icon: 'language',
  overview: $t('endp_index.overview'),

  queryName: 'endpoints',
  queryFields: `{
    _id
    environmentId
    name
    identifier
    type
  }`,
  searchable: false,
  hidden: true,
  titleIsMale: true,

  transformResult: (endpoint) => ({
    ...endpoint,
    title: endpoint.name,
    subtitle: endpoint.identifier,
    labels: [
      {
        list: {
          text: $t('endp_index.list'),
          color: 'pink',
          textColor: 'white'
        },
        view: {
          text: $t('endp_index.seeOne')
        },
        form: {
          text: $t('endp_index.form'),
          color: 'purple',
          textColor: 'white'
        }
      }[endpoint.type || EndpointType.List]
    ],
    href: `/${endpoint.environmentId}/components/endpoints/${endpoint._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Endpoint>
