import { ComponentType, Validation } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}



export const validations = {
  name: 'validation',
  namespace: 'validations',
  title: $t('validations_index.title'),
  titleSingle: $t('validations_index.titleSingle'),
  icon: 'spellcheck',
  color: 'blue-grey',
  overview: $t('validations_index.overview'),
  queryName: 'validations',
  queryFields: `{
    _id
    name
    validationTypeId
  }`,
  searchable: true,
  hidden: true,

  transformResult: (validation) => ({
    ...validation,
    name: validation.name,
    title: validation.name,
    subtitle: validation.validationTypeId,
    href: `validations/${validation._id}`
  }),

  list: () => asyncImport(import('./List.vue')),
  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Validation>
