



import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from '@/plugins/moment'

@Component({})
export default class DateFieldView extends Vue {
  @Prop({ type: String }) value!: string

  get displayValue() {
    if (!this.value) return '--:--'
    return this.value
  }
}
