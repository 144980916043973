import { createDecorator } from 'vue-class-component'
import { DebounceSettings } from 'lodash'
import _debounce from 'lodash/debounce'

/**
 * Debounces a Vue method (preventing it from getting called too often)
 */
export const Debounce = function (wait?: number, options?: DebounceSettings) {
  return createDecorator(function (options, key) {
    if (!options.methods) options.methods = {}
    const originalMethod = options.methods[key]
    if (!originalMethod)
      throw new Error('@Debounce can only be used in methods.')
    // @ts-ignore
    options.methods[key] = _debounce(originalMethod, wait, options)
  })
}
