



import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from '@/plugins/moment'

@Component({})
export default class DateRangeFieldView extends Vue {
  @Prop({ type: String }) value!: string

  get displayValue() {
    return this.value
      .split('::')
      .map((v) => moment(v).format('DD-MM-YYYY'))
      .join(String(this.$t('daterange_view.to')))
  }
}
