


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface StringFieldOptions {
  icon?: string
  validatePattern?: string
  validateCaseInsensitive?: boolean
  validateCustomError?: string
  placeholder?: string
  min?: number
  max?: number
  noicon?: boolean
}

@Component({})
export default class StringField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({ type: Array, required: false }) rules!: Record<string, any>[]
  /** Rules disabled */
  @Prop({ type: Boolean, default: false }) norules!: boolean
  /** Icon disabled */
  @Prop({ type: Boolean, default: true }) noicon!: boolean
  /** outlined prop */
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  /** dense prop */
  @Prop({ type: Boolean, default: true }) dense!: boolean
  /** Validation Rules */

  get getIcon() {
    if (this.noicon || this.fieldOptions.noicon) return ''
    return this.fieldOptions.icon || 'lock'
  }

  get validationRules() {
    const rules: Array<(data: string) => void> = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('password.script.validationRules.required'))
    }
    // Custom validation pattern
    if (this.fieldOptions.validatePattern) {
      rules.push(
        (v?: string) =>
          !v ||
          !!v.match(
            new RegExp(
              this.fieldOptions.validatePattern!,
              this.fieldOptions.validateCaseInsensitive ? 'i' : ''
            )
          ) ||
          this.fieldOptions.validateCustomError ||
          this.$t('password.script.validationRules.invalid')
      )
    }
    // Length validations
    if (this.fieldOptions.min) {
      rules.push(
        (v?: string) =>
          !v || v.length >= this.fieldOptions.min! || this.$t('password.script.validationRules.short')
      )
    }
    if (this.fieldOptions.max) {
      rules.push(
        (v?: string) =>
          !v || v.length <= this.fieldOptions.max! || this.$t('password.script.validationRules.large')
      )
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): StringFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(...args: string[]) {
    this.$emit('input', ...args)
  }
}
