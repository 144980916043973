import * as boolean from './boolean'
import * as checkbox from './checkbox'
import * as string from './string'
import * as email from './email'
import * as rut from './rut'
import * as password from './password'
import * as phone from './phone'
import * as number from './number'
import * as currency from './currency'
import * as date from './date'
import * as datetime from './datetime'
import * as daterange from './daterange'
import * as file from './file'
import * as fileMultiple from './fileMultiple'
import * as fileURL from './fileURL'
import * as hyperlink from './hyperlink'
import * as textArea from './textArea'
import * as code from './code'
import * as legacyhtml from './legacyhtml'
import * as css from './css'
import * as js from './js'
import * as json from './json'
import * as content from './content'
import * as select from './select'
import * as blockSelect from './blockSelect'
import * as hookSelect from './hookSelect'
import * as percentage from './percentage'
import * as color from './color'
import * as oneOf from './oneOf'
import * as manyOf from './manyOf'
import * as signature from './signature'
import * as fingerprintCapture from './fingerprintCapture'
import * as field from './field/index'
import * as document from './document'
import * as fieldOptions from './fieldOptions'
import * as componentSelect from './componentSelect'
import * as collectionSelect from './collectionSelect'
import * as collectionFieldSelect from './collectionFieldSelect'
import * as indicatorSelect from './indicatorSelect'
import * as roleSelect from './roleSelect'
import * as userSelect from './userSelect'
import * as filterSelect from './filterSelect'
import * as formSelect from './formSelect'
import * as tableSelect from './tableSelect'
import * as buttonSelect from './buttonSelect'
import * as validationSelect from './validationSelect'
import * as selectOptions from './selectOptions'
import * as tableFields from './tableFields'
import * as tableFooter from './tableFooter'
import * as mixedChartSeries from './mixedChartSeries'
import * as formFields from './formFields'
import * as location from './location'
import * as rating from './rating'
import * as markdown from './markdown'
import * as range from './range'
import * as time from './time'
import * as object from './object'

export default {
  boolean,
  checkbox,
  string,
  email,
  rut,
  password,
  phone,
  number,
  currency,
  date,
  datetime,
  daterange,
  file,
  fileMultiple,
  fileURL,
  hyperlink,
  textArea,
  code,
  css,
  js,
  json,
  html: code,
  legacyhtml,
  content,
  singleSelect: select,
  multipleSelect: select,
  select,
  percentage,
  blockSelect,
  hookSelect,
  color,
  colorPicker: color,
  oneOf,
  manyOf,
  fingerprintCapture,
  signature,
  field,
  document,
  fieldOptions,
  collectionSelect,
  collectionFieldSelect,
  indicatorSelect,
  roleSelect,
  userSelect,
  filterSelect,
  formSelect,
  tableSelect,
  buttonSelect,
  validationSelect,
  selectOptions,
  tableFields,
  tableFooter,
  mixedChartSeries,
  formFields,
  location,
  componentSelect,
  rating,
  markdown,
  range,
  time,
  object
}
