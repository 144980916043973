import { ComponentType, Link, LinkType } from '@/models'
import _snakeCase from 'lodash/snakeCase'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}
const normalizedIcon = (name: string | undefined) => {
  const icon = name || ''
  return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
}

const getSubtitle = (link: Partial<Link>) => {
  switch (link.type) {
    case 'path':
      return link.path
    case 'category':
      const fields = link.fields ? link.fields : []
      return fields.map((f) => f.title).join(', ')
    default:
      return ' '
  }
}

export const links = {
  name: 'link',
  namespace: 'links',
  title: $t('links_index.title'),
  titleSingle: $t('links_index.titleSingle'),
  description: 'Permite enlazar hacia una vista desde la sidebar',
  icon: 'attachment',
  overview: $t('links_index.overview'),
  queryName: 'links',
  queryFields: `{
    _id
    environmentId
    title
    type
    icon
    path
    fields {
      title
    }
  }`,
  searchable: false,
  hidden: true,
  titleIsMale: true,

  transformResult: (link) => ({
    ...link,
    title: link.title,
    subtitle: getSubtitle(link),
    icon: normalizedIcon(link.icon),
    labels: [
      link.type
        ? {
            path: {
              text: $t('links_index.labels.path'),
              color: 'blue',
              textColor: 'white'
            },
            category: {
              text: $t('links_index.labels.category'),
              color: 'cyan',
              textColor: 'white'
            },
            userMenu: {
              text: $t('links_index.labels.userMenu'),
              color: 'green',
              textColor: 'white'
            },
            divider: {
              text: $t('links_index.labels.divider'),
              color: 'grey',
              textColor: 'white'
            }
          }[link.type || LinkType.Single]
        : { text: $t('links_index.labels.or') }
    ],
    href: `/${link.environmentId}/components/links/${link._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Link>
