export enum ValueType {
  /** Fixed value */
  Fixed = 'fixed',
  /** Value taken from a parameter (either from the view or the hook context) */
  Parameter = 'parameter',
  /** Value input by the user */
  Editable = 'editable',
  /** Environment variable */
  Environment = 'env',
  /** Result of an indicator */
  Indicator = 'indicator',
  /** Dynamic value, only available in forms, updates as the user types */
  Dynamic = 'dynamic',
  /** Section (forms only) */
  Section = 'section'
}
