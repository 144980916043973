import { ComponentType, EnvironmentUser } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const test = `Palabra del diccionario: ${$t('test.title')}`

export const environmentUsers = {
  name: 'environmentUser',
  namespace: 'environmentUsers',
  title: $t('enviro_index.title'),
  titleSingle: $t('enviro_index.titleSingle'),
  description: $t('enviro_index.description'),
  icon: 'person',
  overview: $t('enviro_index.overview'),

  queryName: 'environmentUsers',
  queryFields: `{
    _id
    environmentId
    email
    resolvedRoles {
      _id
      name
    }
  }`,
  searchable: true,
  hidden: true,
  titleIsMale: true,

  transformResult: (envUser) => ({
    ...envUser,
    title: envUser.email,
    subtitle:
      ' ' +
      (envUser.resolvedRoles
        ?.map((r) => r.name)
        .slice(0, 3)
        .join(' | ') || '') +
      (envUser.resolvedRoles && envUser.resolvedRoles.length > 3
        ? ` | ...`
        : ''),
    labels: [
      {
        text: envUser.resolvedRoles?.length
          ? envUser.resolvedRoles.length +
            (envUser.resolvedRoles.length === 1 ? ' Rol' : ' Roles')
          : $t('enviro_index.noRoles'),
        color: envUser.resolvedRoles?.length ? 'primary' : ''
      }
    ],
    href: `/${envUser.environmentId}/components/environmentUsers/${envUser._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<EnvironmentUser>
