












































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

@Component({})
export default class TOTPDialog extends Vue {
  @Prop({ type: String, default: $t('dialogs_totp.script.prop.twoFactor') })
  title?: string
  @Prop({
    type: String,
    default: $t('dialogs_totp.script.prop.code')
  })
  message?: string
  @Prop({ type: String, default: $t('dialogs_totp.script.prop.continue') }) okButtonText?: string
  @Prop({ type: String, default: 'vpn_key' }) okButtonIcon?: string
  @Prop({ type: String, default: 'primary' }) okButtonColor?: string
  @Prop({ type: String, default: $t('dialogs_totp.script.prop.cancel') }) cancelButtonText?: string
  @Prop({ type: String, default: '' }) cancelButtonIcon?: string
  @Prop({ type: String, default: '' }) cancelButtonColor?: string
  @Prop({ type: String, default: '' }) placeholder?: string
  @Prop({ type: String, default: '' }) defaultValue?: string
  @Prop({ type: Boolean, default: false }) invalidAttempt!: boolean

  value = ''

  @Watch('defaultValue', { immediate: true })
  setDefaultValue(defaultValue: string) {
    this.value = defaultValue
  }
}
