import { FilterWithSchema, PaginatedQueryResult, Table, FormSchema } from '../'

export interface Chart {
  _id: string
  name: string
  title: string
  environmentId: string
  createdAt: Date
  chartTypeId: string
  collectionId: string
  filtersIds: string[]
  allowsNoFilter: boolean
  options: any
  filterByDefault: string
  filters?: FilterWithSchema[]
  disableRefresh: boolean
}

export interface ChartType {
  _id: string
  name: string
  optionsParams: FormSchema
}

export enum ChartRenderType {
  BarByDate = 'barByDate',
  BarByValue = 'barByValue',
  BarGroupedByValues = 'barGroupedByValues',
  BarGroupedByValuesIndicator = 'barGroupedByValuesIndicator',
  LineByDate = 'lineByDate',
  LineByValue = 'lineByValue',
  PieGroupedByValues = 'pieGroupedByValues',
  Table = 'table'
}

export interface ChartResult {
  renderType: ChartRenderType
  valueType?: string
}

export interface BarGroupedByValuesChartResult extends ChartResult {
  renderType: ChartRenderType.BarGroupedByValues
  charts: string[]
  datasets: Record<string, Record<string, any>[]>
  xLabelAngle: number
  yLabelAngle: number
  titles?: { title: string }[]
  links?: string
  specificColors: string
  margins: {
    left: number
    right: number
    top: number
    bottom: number
  }
}

export interface PieGroupedByValuesChartResult {
  renderType: ChartRenderType.PieGroupedByValues
  colorDomain: number[]
  colorRange: string[]
  colorType: string
  dataset: {
    angle: number
    angle0: number
    color: string
    label: string
    radius: number
    radius0: number
    value: number
  }[]
  legend: {
    continuous: {
      endColor: string
      endTitle: string
      midColor: string
      midTitle: string
      startColor: string
      startTitle: string
    }
    discrete: {
      items: {
        color: string
        title: string
      }[]
    }
    use: 'continuous' | 'discrete'
  }
  perimeterLength: number
}

export interface TableChartResult extends PaginatedQueryResult<any> {
  renderType: ChartRenderType.Table
  table: Table
}

export interface MixedChartSeries {
  _id: string
  type?: MixedChartSeriesType
  displayType?: MixedChartSeriesDisplayType
  title?: string
  label?: string
  color?: string
  link?: string
  field?: string
  fieldX?: string
  filterId?: string
  sortType?: MixedChartSortType
  mathOperation?: string
  referenceAxis?: boolean
}

export enum MixedChartSeriesType {
  Average = '$avg',
  Sum = '$sum',
  First = '$first',
  Last = '$last',
  Min = '$min',
  Max = '$max',
  StdDevPop = '$stdDevPop',
  StdDevSamp = '$stdDevSamp'
}

export enum MixedChartSeriesDisplayType {
  Line = 'line',
  Column = 'column',
  Area = 'area',
  Scatter = 'scatter',
  Bubble = 'bubble'
}

export enum MixedChartSortType {
  NoPreference = '',
  Ascending = 'ASC',
  Descending = 'DESC'
}
