import { ComponentType, Indicator } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const indicators = {
  name: 'indicator',
  namespace: 'indicators',
  title: $t('indicators_index.title'),
  titleSingle: $t('indicators_index.titleSingle'),
  titleIsMale: true,
  description: $t('indicators_index.description'),
  icon: 'notes',
  color: 'cyan',
  overview: $t('indicators_index.overview'),

  queryName: 'indicators',
  queryFields: `{
    _id
    name
    title
    indicatorTypeId
  }`,
  searchable: true,

  transformResult: (indicator) => ({
    ...indicator,
    name: indicator.name,
    title: indicator.name,
    subtitle: indicator.indicatorTypeId,
    href: `indicators/${indicator._id}`
  }),

  viewSkeleton: 'card-heading, text',

  create: () => asyncImport(import('./Create.vue')),
  list: () => asyncImport(import('./List.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Indicator>
