import { RootState } from '@/store/index'
import { Module } from 'vuex'

export interface SnackbarState {
  /** Snackbar Visibility */
  visible: boolean
  /** Snackbar Text */
  text: string
  /** Snackbar Color */
  color: string
  /** Show at the bottom of the page */
  bottom: boolean
  /** Show at the top of the page */
  top: boolean
  /** Show at the left of the page */
  left: boolean
  /** Show at the right of the page */
  right: boolean
  /** Timeout in milliseconds */
  timeout: number
}

const defaults = {
  text: '',
  color: '',
  bottom: true,
  top: false,
  left: false,
  right: false,
  timeout: 5000
}

/**
 * Snackbar module - state of the global snackbar.
 */
const SnackbarModule: Module<SnackbarState, RootState> = {
  namespaced: true,
  state: {
    ...defaults,
    visible: false
  },
  mutations: {
    showSnackbar(state) {
      state.visible = true
    },
    setSnackbar(state, payload: Partial<SnackbarState>) {
      Object.assign(state, defaults, payload)
    },
    hideSnackbar(state) {
      state.visible = false
    }
  },
  actions: {
    showSnackbar({ commit }, payload: Partial<SnackbarState>) {
      if (payload.color === 'error') navigator.vibrate([50, 100, 50, 100, 50])
      commit('setSnackbar', payload)
      commit('showSnackbar')
    }
  }
}

export default SnackbarModule
