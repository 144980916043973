























































import { Vue, Component, Prop } from 'vue-property-decorator'
import _debounce from 'lodash/debounce'
import { Throttle } from '@/utils/decorators'

@Component({})
export default class ComponentResizer extends Vue {
  @Prop({ type: String, required: true }) value!: string
  @Prop({ type: String }) color?: string
  @Prop({ type: Boolean, default: false }) invisibleHandle!: boolean

  overlayVisible = false
  initialX = 0
  initialWidth = 0
  initialValue = 0
  steps = 0

  onDragStart(e: DragEvent) {
    this.overlayVisible = true
    e.dataTransfer!.setDragImage(document.createElement('canvas'), 1, 1)
    this.initialX = e.screenX
    this.initialWidth = (this.$el as HTMLDivElement).offsetWidth
    this.initialValue = parseInt(this.value, 10)
    this.steps = this.initialWidth / this.initialValue
  }

  @Throttle(150, { leading: true, trailing: true })
  onDrag(e: DragEvent) {
    if (!e.screenX) return
    const targetValue = Math.min(
      12,
      Math.max(
        1,
        this.initialValue + Math.round((e.screenX - this.initialX) / this.steps)
      )
    )
    if (targetValue.toString() !== this.value)
      this.$emit('input', targetValue.toString())
  }

  onDragEnd(e: DragEvent) {
    this.overlayVisible = false
    this.initialX = 0
    this.initialWidth = 0
    this.initialValue = 0
    this.steps = 0
  }
}
