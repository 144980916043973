








import { Component, Vue, Prop } from 'vue-property-decorator'
import numeral from '@/plugins/numeral'

@Component({})
export default class CurrencyFieldView extends Vue {
  @Prop({ type: Number }) value?: number | null

  get displayValue() {
    if (this.value == null) return '-'
    return numeral(this.value).format('$0,0.[00]')
  }
}
