import { ComponentType, Role } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}
export const roles = {
  name: 'role',
  namespace: 'roles',
  title: $t('roles_index.title'),
  titleSingle: $t('roles_index.titleSingle'),
  description: $t('roles_index.description'),
  icon: 'group',
  overview: $t('roles_index.overview'), 
  queryName: 'roles',
  queryFields: `{
    _id
    environmentId
    name
  }`,
  searchable: false,
  hidden: true,
  titleIsMale: true,

  transformResult: (role) => ({
    ...role,
    name: role.name,
    title: role.name,
    subtitle: ' ',
    href: `/${role.environmentId}/components/roles/${role._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Role>
