import { ComponentType, Form } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const forms = {
  name: 'form',
  namespace: 'forms',
  title: $t('forms_index.title'),
  titleSingle: $t('forms_index.titleSingle'),
  titleIsMale: true,
  description: $t('forms_index.description'),
  icon: 'border_color',
  tabIcon: 'short_text',
  color: 'purple',
  overview: $t('forms_index.overview'),

  queryName: 'forms',
  queryFields: `{
    _id
    name
    title
    type
    collectionId
  }`,
  searchable: true,

  transformResult: (form) => ({
    ...form,
    name: form.name,
    title: form.title,
    subtitle:
      {
        create: $t('forms_index.create'),
        update: $t('forms_index.update'),
        upsert: $t('forms_index.upsert'),
        void: $t('forms_index.void'),
      }[form.type!] + form.collectionId,
    href: `forms/${form._id}`,
  }),

  viewSkeleton:
    'heading, table-cell, text, table-cell, text, table-cell, actions',

  create: () => asyncImport(import('./Create.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
} as ComponentType<Form>
