<template>
  <v-app
    :dark="darkMode"
    :class="{
      'mobile-app': mobileApp,
      [mobilePlatform]: true,
      'admin-domain': adminDomain
    }"
  >
    <router-view class="main-container" />
    <div
      :class="{ 'pre-ribbon': true, [`${env}-ribbon`]: true }"
      v-if="preBarVisible"
    >
      <div class="pre-text">
        <div>
          {{ $t('app.div.pre-text.title', { env: env.toUpperCase() }) }}
        </div>
        <div>Codelex Vittra {{ version }}-{{ env }}</div>
      </div>
    </div>
    <v-snackbar
      v-model="snackbarVisible"
      :light="!darkMode"
      :top="snackbarProps.top"
      :bottom="snackbarProps.bottom"
      :left="snackbarProps.left"
      :right="snackbarProps.right"
      :timeout="snackbarProps.timeout"
      multi-line
    >
      {{ snackbarProps.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbarProps.color ? '' : 'primary'"
          v-bind="attrs"
          text
          @click="snackbarVisible = false"
        >
          {{ $t('app.snackbar.ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <dialog-container />
  </v-app>
</template>
<script>
import DialogContainer from '@/components/dialogs/DialogContainer.vue'
import getEnv from '@/plugins/getEnv'
import isAdminDomain from '@/utils/client/isAdminDomain'

export default {
  name: 'App',
  components: {
    DialogContainer
  },
  mounted() {
    if (window.mobileApp) {
      const bootstrap = document.getElementById('bootstrap')
      if (bootstrap) {
        bootstrap.remove()
      }
      this.updateNotch()
      window.addEventListener('resize', () => this.updateNotch())
    } else {
      if (
        window.location.protocol === 'http:' &&
        getEnv('VUE_APP_ENV') !== 'local'
      ) {
        window.location.protocol = 'https:'
      }
    }
    this.$vuetify.theme.dark = this.$store.state.app.darkMode
  },
  data() {
    return {
      env: getEnv('VUE_APP_ENV') || 'staging',
      version: getEnv('VUE_APP_VERSION') || 'dev',
      adminDomain: isAdminDomain()
    }
  },
  methods: {
    updateNotch() {
      // Fix for android "notches"
      if (window.AndroidNotch) {
        const style = document.documentElement.style
        window.AndroidNotch.getInsetTop(
          (px) => style.setProperty('--notch-inset-top', `${px || 24}px`),
          console.error
        )
        window.AndroidNotch.getInsetRight(
          (px) => style.setProperty('--notch-inset-right', `${px}px`),
          console.error
        )
        window.AndroidNotch.getInsetBottom(
          (px) => style.setProperty('--notch-inset-bottom', `${px}px`),
          console.error
        )
        window.AndroidNotch.getInsetLeft(
          (px) => style.setProperty('--notch-inset-left', `${px}px`),
          console.error
        )
      }
    }
  },
  watch: {
    darkMode(value) {
      this.$vuetify.theme.dark = value
    }
  },
  computed: {
    darkMode() {
      return this.$store.state.app.darkMode
    },
    mobileApp() {
      return window.mobileApp
    },
    mobilePlatform() {
      if (!this.mobileApp || !window.cordova) return 'desktop'
      return window.cordova.platformId
    },
    preBarVisible() {
      return (
        !['prod', 'bigdata'].includes(this.env) ||
        (this.$store.state.auth.user &&
          (this.$store.state.auth.user.roles.indexOf('admin') >= 0 ||
            this.$store.state.auth.user.roles.indexOf('superAdmin') >= 0))
      )
    },
    snackbarVisible: {
      get() {
        return this.$store.state.snackbar.visible
      },
      set() {
        return this.$store.commit('snackbar/hideSnackbar')
      }
    },
    snackbarProps: {
      get() {
        return this.$store.state.snackbar
      }
    }
  }
}
</script>
<style>
@font-face {
  font-family: 'Aspira Wide Regular';
  src: url('/fonts/AspiraWide/Aspira-Wide-Regular.ttf') format('TrueType');
}
@font-face {
  font-family: 'Aspira Wide Demi';
  src: url('/fonts/AspiraWide/Aspira-Wide-Regular.ttf') format('TrueType');
}
@font-face {
  font-family: 'Aspira Wide Light';
  src: url('/fonts/AspiraWide/Aspira-Wide-Regular.ttf') format('TrueType');
}
@font-face {
  font-family: 'Aspira Wide Medium';
  src: url('/fonts/AspiraWide/Aspira-Wide-Regular.ttf') format('TrueType');
}
@font-face {
  font-family: 'Aspira Wide Bold';
  src: url('/fonts/AspiraWide/Aspira-Wide-Regular.ttf') format('TrueType');
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Light.ttf') format('TrueType');
  font-weight: 300;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Regular.ttf') format('TrueType');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Medium.ttf') format('TrueType');
  font-weight: 500;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-SemiBold.ttf') format('TrueType');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Bold.ttf') format('TrueType');
  font-weight: 700;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-ExtraBold.ttf') format('TrueType');
  font-weight: 800;
}

#collection-list__items .v-list-item--active {
  background-color: rgba(130, 186, 247, 0.185);
}

.v-btn__content {
  text-transform: capitalize;
  letter-spacing: 1px;
  font-family: 'Inter', sans-serif;
}
.theme--dark .newdesign .v-input__slot {
  background-color: var(--v-background-4-base);
  border-color: 1px solid var(--v-background-6-base);
  caret-color: var(--v-primary-base);
}
.material-icons {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
}
</style>
