























import { Vue, Component, Prop } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

@Component({})
export default class ConfirmDeleteDialog extends Vue {
  @Prop({ type: String }) title?: string
  @Prop({ type: String }) message?: string
  @Prop({ type: String, default: $t('dialogs_delete.script.prop.delete') })
  okButtonText?: string
  @Prop({ type: String, default: 'delete' }) okButtonIcon?: string
  @Prop({ type: String, default: '' }) okButtonColor?: string
  @Prop({ type: String, default: $t('dialogs_delete.script.prop.cancel') })
  cancelButtonText?: string
  @Prop({ type: String, default: '' }) cancelButtonIcon?: string
  @Prop({ type: String, default: '' }) cancelButtonColor?: string
}
