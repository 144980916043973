import { ComponentType, Button } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}
export const buttons = {
  name: 'button',
  namespace: 'buttons',
  title: $t('buttons_index.title'),
  titleSingle: $t('buttons_index.titleSingle'),
  titleIsMale: true,
  description: $t('buttons_index.description'),
  icon: 'label_outline',
  tabIcon: 'label_outline',
  color: 'orange',
  overview: $t('buttons_index.overview'),

  queryName: 'buttons',
  queryFields: `{
    _id
    name
    title
  }`,
  searchable: true,

  transformResult: (button) => ({
    ...button,
    name: button.name,
    title: button.title,
    subtitle: button.name,
    href: `buttons/${button._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
  view: () => asyncImport(import('./View.vue'))
} as ComponentType<Button>
