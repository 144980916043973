import { ComponentType, Task } from '@/models'
import EditorLoading from './FullViewEdit/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}
export const tasks = {
  name: 'task',
  namespace: 'tasks',
  title: $t('tasks_index.title'),
  titleSingle: $t('tasks_index.titleSingle'),
  description: $t('tasks_index.description'),
  icon: 'event_available',
  color: 'red',
  overview: $t('tasks_index.overview'),
  queryName: 'tasks',
  queryFields: `{
    _id
    name
  }`,
  searchable: true,
  hidden: true,

  transformResult: (task) => ({
    ...task,
    name: task.name
  }),
  fullViewEdit: () =>
    ({
      component: import('./FullViewEdit/FullViewEdit.vue').then(
        (e) => e.default
      ),
      loading: EditorLoading,
      error: ComponentError
    } as unknown as Vue.AsyncComponent)
} as ComponentType<Task>
