













































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import delay from '@/utils/async/delay'

declare global {
  interface Window {
    Fingerprint: any
  }
}

@Component({
  components: {
    Loading
  }
})
export default class FingerprintCaptureModal extends Vue {
  @Prop({ type: Boolean, required: true }) open!: boolean
  @Prop({ type: Boolean }) captured?: boolean
  @Prop({ type: String }) fingerprintImage?: string

  initializing = true
  fp = new window.Fingerprint.WebApi()
  flashing = false

  pendingFingerprintImage = ''
  pendingFingerprintWsq: any = null
  statusIcon = ''
  statusText = ''
  cancelButtonText = this.$t('fingerPrint_capture.script.cancelBtn')

  get modalOpen() {
    return this.open
  }

  set modalOpen(v: boolean) {
    this.$emit('update:open', v)
  }

  @Watch('open')
  async initOrReset(v: boolean) {
    if (v) {
      this.statusIcon = 'fingerprint'
      await this.initialize()
      if (!this.captured) await this.capture()
    } else {
      this.initializing = true
      this.pendingFingerprintImage = ''
      this.pendingFingerprintWsq = null
      this.statusIcon = ''
      this.statusText = ''
      this.cancelButtonText = this.$t('fingerPrint_capture.script.cancelBtn')
    }
  }

  async initialize() {
    try {
      await this.checkDevices()
      this.initializing = false
    } catch (e) {
      this.initializing = false
      this.statusIcon = 'error'
      this.statusText =
        e.message || this.$t('fingerPrint_capture.script.initialize.text')
      this.cancelButtonText = this.$t(
        'fingerPrint_capture.script.initialize.closeBtn'
      )
    }
  }

  async flash() {
    this.flashing = true
    await delay(3000)
    this.flashing = false
  }

  async capture() {
    try {
      this.pendingFingerprintImage = ''
      this.pendingFingerprintWsq = null
      this.cancelButtonText = this.$t('fingerPrint_capture.script.cancelBtn')
      this.statusText = String(
        this.$t('fingerPrint_capture.script.capture.biometricFirst')
      )
      const fingerprintImage =
        'data:image/png;base64,' +
        window.Fingerprint.b64UrlTo64(await this.captureSamples(5))
      this.statusText = String(
        this.$t('fingerPrint_capture.script.capture.firstCapture')
      )
      await this.flash()
      this.statusText = String(
        this.$t('fingerPrint_capture.script.capture.biometricSecond')
      )
      const wsq = await this.captureSamples(3)
      this.statusText = String(
        this.$t('fingerPrint_capture.script.capture.secondCapture')
      )
      await this.flash()
      this.pendingFingerprintImage = fingerprintImage
      this.pendingFingerprintWsq = wsq
    } catch (e) {
      this.initializing = false
      this.statusIcon = 'error'
      this.statusText =
        e.message || this.$t('fingerPrint_capture.script.capture.error')
      this.cancelButtonText = this.$t(
        'fingerPrint_capture.script.capture.closeBtn'
      )
    }
  }

  async checkDevices() {
    const devCount = await this.fp.enumerateDevices()
    if (devCount <= 0) {
      throw new Error(
        String(this.$t('fingerPrint_capture.script.checkDevices.error'))
      )
    }
  }

  async captureSamples(format = 5) {
    await this.checkDevices()
    await this.fp.startAcquisition(format)
    return new Promise((resolve, reject) => {
      this.fp.onSamplesAcquired = (s: any) => {
        this.fp.stopAcquisition()
        const samples = JSON.parse(s.samples)
        resolve(samples[0])
      }
    })
  }

  save() {
    this.$emit('save', {
      image: this.pendingFingerprintImage,
      wsq: this.pendingFingerprintWsq
    })
    this.modalOpen = false
  }

  cancel() {
    this.fp.stopAcquisition()
    this.modalOpen = false
  }
}
