































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface StringFieldOptions {
  icon?: string
  validatePattern?: string
  validateCaseInsensitive?: boolean
  validateCustomError?: string
  min?: number
  max?: number
  placeholder?: string
  mask?: string
}

@Component({})
export default class StringField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({ type: Array, required: false }) rules!: Record<string, any>[]
  /** Rules disabled */
  @Prop({ type: Boolean, default: false }) norules!: boolean
  /** Icon disabled */
  @Prop({ type: Boolean, default: false }) icon!: boolean
  /** outlined prop */
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  /** dense prop */
  @Prop({ type: Boolean, default: true }) dense!: boolean

  get getIcon() {
    if (this.icon || this.fieldOptions.icon) {
      return this.fieldOptions.icon || 'text_format'
    }
    return ''
  }

  /** Validation Rules */
  get validationRules() {
    if (this.norules) return []
    const rules = this.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push(
        (v?: string) => !!v || this.$t('string.script.validationRules.required')
      )
    }
    // Custom validation pattern
    if (this.fieldOptions.validatePattern) {
      rules.push(
        (v?: string) =>
          !v ||
          !!v.match(
            new RegExp(
              this.fieldOptions.validatePattern!,
              this.fieldOptions.validateCaseInsensitive ? 'i' : ''
            )
          ) ||
          this.fieldOptions.validateCustomError ||
          this.$t('string.script.validationRules.invalidFormat')
      )
    }
    // Length validations
    if (this.fieldOptions.min) {
      rules.push(
        (v?: string) =>
          !v ||
          v.length >= this.fieldOptions.min! ||
          this.$t('string.script.validationRules.tooShort')
      )
    }
    if (this.fieldOptions.max) {
      rules.push(
        (v?: string) =>
          !v ||
          v.length <= this.fieldOptions.max! ||
          this.$t('string.script.validationRules.tooLarge')
      )
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): StringFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get currentValue() {
    return this.value
  }

  set currentValue(value: string) {
    this.$emit('input', value)
  }

  clearValue() {
    this.$emit('input', null)
  }
}
