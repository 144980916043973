import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/locales'
import getEnv from './getEnv'

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.locale || getEnv('VUE_APP_DEFAULT_LOCALE') || 'en',
  fallbackLocale: 'en',
  messages: locales
})
