









































































import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { FormField } from '@/models'
import { ApolloError, ApolloQueryResult } from 'apollo-client'
import asyncImport from '@/utils/client/asyncImport'
import { showDialogPromise } from '@/components/dialogs'
import runtimeParams from '@/utils/runtime/runtimeParams'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'

interface OneOfFieldOptions {
  collectionId: string
  labelKey: string
  valueKey: string
  imageKey?: string
  subtitleKey?: string
  filterId?: string
  filterFields?: string
  createForm?: string
  disableRefresh?: boolean
  icon?: string
  noicon?: boolean
  placeholder?: string
  norules?: boolean
  rules?: Record<string, any>
}

interface OneOfItem {
  label: string
  value: string
  subtitle?: string
  image?: string
  icon?: string
}

@Component({
  components: {
    CollectionWatcher
  },
  apollo: {
    items: {
      query: gql`
        query getFormOneOfSelectOptions(
          $environmentId: ID
          $formId: ID
          $collectionId: ID
          $tableId: ID
          $fieldName: String
          $collectionFieldName: String
          $searchQuery: String
          $limit: Float
          $filterIdForItems: ID
          $filterId: ID
          $filterOptions: JSON
          $currentValue: String
        ) {
          items: selectOptions(
            environmentId: $environmentId
            formId: $formId
            collectionId: $collectionId
            tableId: $tableId
            fieldName: $fieldName
            collectionFieldName: $collectionFieldName
            searchQuery: $searchQuery
            limit: $limit
            filterIdForItems: $filterIdForItems
            filterId: $filterId
            filterOptions: $filterOptions
            currentValue: $currentValue
          ) {
            label
            value
            subtitle
            image
            icon
          }
        }
      `,
      variables() {
        return {
          environmentId: this.environmentVariables.environmentId,
          formId: this.environmentVariables.formId,
          collectionId: this.environmentVariables.collectionId,
          tableId: this.environmentVariables.tableId,
          fieldName: this.name,
          collectionFieldName: this.name,
          searchQuery: this.searchQuery,
          limit: 200,
          filterIdForItems: this.filterIdForItems,
          filterId: this.fieldOptions.filterId,
          filterOptions: this.filterOptions,
          currentValue: this.value
        }
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      result(result: ApolloQueryResult<{ items?: OneOfItem[] }>) {
        if (!this.fieldOptions.filterId) return result.data.items
        if (result.data.items) {
          this.filterHasValidationErrors = false
        } else {
          this.filterHasValidationErrors = true
          this.items = []
        }
        return result.data.items
      }
    }
  }
})
export default class OneOfField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >
  /** Rules prop */
  @Prop({ type: Array, required: false }) rules!: Record<string, any>[]
  /** Rules disabled */
  @Prop({ type: Boolean, default: false }) norules!: boolean
  /** Icon disabled */
  @Prop({ type: Boolean, default: true }) noicon!: boolean
  /** outlined prop */
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  /** dense prop */
  @Prop({ type: Boolean, default: true }) dense!: boolean

  /** Filter parameters */
  @Inject() fieldValues?: () => Record<string, any>

  filterHasValidationErrors = false

  searchQuery = ''
  items: OneOfItem[] = []

  /** Additional field options */
  get fieldOptions(): OneOfFieldOptions {
    return this.schema.fieldOptions || this.schema.options || {}
  }

  get getIcon() {
    if (this.noicon || this.fieldOptions.noicon) return ''
    return this.fieldOptions.icon || 'search'
  }

  get filterIdForItems() {
    return this.environmentVariables.filterId
  }

  /** Validation Rules */
  get validationRules() {
    if (this.norules || this.fieldOptions.norules) return []

    const rules = this.rules || this.fieldOptions.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push(
        (v?: string) => !!v || this.$t('oneOf.script.validationRules.required')
      )
    }
    return rules
  }

  async openCreateModal() {
    this.searchQuery = ''
    const field = this.$refs.field as any
    field.blur()

    const result = (await showDialogPromise({
      id: 'form-' + this.fieldOptions.createForm,
      component: () =>
        asyncImport(import('@/components/componentTypes/forms/View.vue')),
      wrapInCard: true,
      props: {
        environmentId: this.environmentVariables.environmentId,
        componentId: this.fieldOptions.createForm,
        itemDefinition: {
          id: this.fieldOptions.createForm,
          type: 'form',
          sizeSmall: '12',
          sizeLarge: '12',
          formId: this.fieldOptions.createForm,
          namespace: 'forms'
        },
        showCloseButton: true,
        viewParams: {}
      },
      dismissable: true
    })) as any

    if (result && result._id) {
      this.$emit('input', result[this.fieldOptions.valueKey])
      field.blur()
      await this.$apollo.queries.items.refetch()
    }
  }

  set selectedItemId(value: string) {
    this.searchQuery = ''
    this.$emit('input', value)
  }

  get selectedItemId() {
    return this.value
  }

  /** Options for the filter */
  get filterOptions() {
    const fieldValues = (this.fieldValues && this.fieldValues()) || {}
    const params: Record<string, any> = {}
    const fields = this.fieldOptions.filterFields?.trim().split(',') || []
    for (const field of fields) {
      params[field] = fieldValues[field]
    }
    return params
  }

  async update() {
    if (this.fieldOptions.disableRefresh) {
      return
    }
    return this.$apollo.queries.items.refetch()
  }
}
