






































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface SelectFieldOptions {
  placeholder?: string
  options: {
    value: string
    label: string
  }[]
  multi?: boolean
  disabled?: boolean
}

@Component({})
export default class SelectField extends Vue {
  /** Current Value */
  @Prop({ type: [String, Array], default: '' }) value!: string | string[]
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({ type: Array, required: false }) rules!: Record<string, any>[]
  /** Rules disabled */
  @Prop({ type: Boolean, default: false }) norules!: boolean
  /** Icon disabled */
  @Prop({ type: Boolean, default: true }) noicon!: boolean
  /** outlined prop */
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  /** dense prop */
  @Prop({ type: Boolean, default: true }) dense!: boolean

  remove(value: string) {
    const index = this.selectedValues.indexOf(value)
    if (index !== -1) {
      this.selectedValues.splice(index, 1)
    }
  }

  get getIcon() {
    if (this.noicon) return ''
    if (this.schema.fieldType === 'multipleSelect') {
      return 'playlist_add_check'
    }
    return 'list'
  }

  get isDisabled() {
    return this.disabled || this.fieldOptions.disabled
  }

  /** Validation Rules */
  get validationRules() {
    if (this.norules) return []

    const rules = this.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push(
        (v?: string) => !!v || this.$t('select.script.validationRules.required')
      )
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): SelectFieldOptions {
    return this.schema.fieldOptions || this.schema.options || { options: [] }
  }

  /** Input event handler */
  handleInput(...args: any[]) {
    this.$emit('input', ...args)
  }

  /** Current Value */
  get selectedValue() {
    if (!this.value) return ''
    return typeof this.value !== 'object' ? this.value : this.value[0]
  }

  set selectedValue(value: string) {
    this.$emit('input', this.fieldOptions.multi ? value[0] : value)
  }

  /** Current Values (multi) */
  get selectedValues() {
    return typeof this.value !== 'object' ? [this.value] : this.value
  }

  set selectedValues(value: string[]) {
    this.$emit('input', value)
  }
}
