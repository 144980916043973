import Vue from 'vue'
import Vuetify from 'vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

import es from 'vuetify/src/locale/es'
import en from 'vuetify/src/locale/en'

// @ts-ignore
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import { VuetifyThemeVariant } from 'vuetify/types/services/theme'
import isAdminDomain from '@/utils/client/isAdminDomain'
import i18n from './i18n'

const defaultColors: Partial<VuetifyThemeVariant> = {
  'primary-50': '#E5F2FF',
  'primary-100': '#CFE7FF',
  'primary-200': '#A9D0FF',
  'primary-300': '#76AFFF',
  'primary-400': '#407BFF',
  'primary-500': '#1648FF',
  'primary-600': '#002DFF',
  'primary-700': '#002AE1',
  'primary-800': '#011CB2',
  'primary-900': '#01138C',

  disabled: '#F9FAFB',
  'disabled-50': '#F9FAFB',
  'disabled-100': '#F3F4F6',
  'disabled-200': '#E5E7EB',
  'disabled-300': '#D1D5DB',
  'disabled-400': '#9CA3AF',
  'disabled-500': '#6B7280',
  'disabled-600': '#4B5563',
  'disabled-700': '#374151',
  'disabled-800': '#1F2937',
  'disabled-900': '#111827',
}

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    dark: isAdminDomain(),
    themes: {
      light: {
        primary: '#808080',
        secondary: '#C6C6C6',
        accent: '#808080',

        background: '#EDEEF5',
        'background-1': '#EDEEF5',
        'background-2': '#E6E8F0',
        'background-3': '#D5D9E5',
        'background-4': '#B4BCCC',
        'background-5': '#929EB2',
        'background-6': '#718299',

        foreground: '#111433',
        'foreground-1': '#111433',
        'foreground-2': '#14213B',
        'foreground-3': '#2B3C5F',
        'foreground-4': '#4B6384',
        'foreground-5': '#7389A8',
        'foreground-6': '#A3B4CC',

        success: '#22C55E',
        'success-50': '#F0FDF4',
        'success-100': '#DCFCE7',
        'success-200': '#BBF7D0',
        'success-300': '#86EFAC',
        'success-400': '#4ADE80',
        'success-500': '#22C55E',
        'success-600': '#16A34A',
        'success-700': '#16A34A',
        'success-800': '#166534',
        'success-900': '#14532D',

        error: '#FA3939',
        'error-50': '#FFF1F1',
        'error-100': '#FFE0E0',
        'error-200': '#FFC7C7',
        'error-300': '#FF9F9F',
        'error-400': '#FF6868',
        'error-500': '#FA3939',
        'error-600': '#E81A1A',
        'error-700': '#C91212',
        'error-800': '#A11313',
        'error-900': '#861616',

        warning: '#F59F0A',
        'warning-50': '#FFFBEB',
        'warning-100': '#FEF3C8',
        'warning-200': '#FDE68B',
        'warning-300': '#FCD44F',
        'warning-400': '#FBBD23',
        'warning-500': '#F59F0A',
        'warning-600': '#DB7706',
        'warning-700': '#B35309',
        'warning-800': '#91400D',
        'warning-900': '#76350F',

        info: '#4673FB',
        'info-50': '#EFF4FF',
        'info-100': '#DAE5FF',
        'info-200': '#BDD2FF',
        'info-300': '#90B6FF',
        'info-400': '#5C90FE',
        'info-500': '#4673FB',
        'info-600': '#2046F0',
        'info-700': '#1832DD',
        'info-800': '#1A2AB3',
        'info-900': '#1B2A8D',

        ...defaultColors,
      },
      dark: {
        primary: '#808080',
        secondary: '#C6C6C6',
        accent: '#E5E5E5',

        background: '#131532',
        'background-1': '#131532',
        'background-2': '#1A1C3D',
        'background-3': '#1B2142',
        'background-4': '#22294D',
        'background-5': '#2B3457',
        'background-6': '#353E61',

        foreground: '#FAFCFF',
        'foreground-1': '#FAFCFF',
        'foreground-2': '#EBF0F7',
        'foreground-3': '#B3BDCC',
        'foreground-4': '#5D6F89',
        'foreground-5': '#2E405C',
        'foreground-6': '#141C29',

        success: '#5DC31F',
        'success-50': '#F2FDE8',
        'success-100': '#E1FACD',
        'success-200': '#C6F4A2',
        'success-300': '#9FEB6B',
        'success-400': '#7CDD3C',
        'success-500': '#5DC31F',
        'success-600': '#469C14',
        'success-700': '#367714',
        'success-800': '#2F5E16',
        'success-900': '#295017',

        error: '#FF2323',
        'error-50': '#FFF0F0',
        'error-100': '#FFDDDD',
        'error-200': '#FFC0C0',
        'error-300': '#FF9494',
        'error-400': '#FF5757',
        'error-500': '#FF2323',
        'error-600': '#F00000',
        'error-700': '#D70000',
        'error-800': '#B10303',
        'error-900': '#920A0A',

        warning: '#F97316',
        'warning-50': '#FFF7ED',
        'warning-100': '#FFEDD5',
        'warning-200': '#FED7AA',
        'warning-300': '#FDBA74',
        'warning-400': '#FB923C',
        'warning-500': '#F97316',
        'warning-600': '#EA580C',
        'warning-700': '#C2410C',
        'warning-800': '#9A3412',
        'warning-900': '#7C2D12',

        info: '#245BFF',
        'info-50': '#EEF4FF',
        'info-100': '#D9E5FF',
        'info-200': '#BCD2FF',
        'info-300': '#8EB6FF',
        'info-400': '#598FFF',
        'info-500': '#245BFF',
        'info-600': '#1B43F5',
        'info-700': '#1430E1',
        'info-800': '#1728B6',
        'info-900': '#19288F',

        ...defaultColors,
      },
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { es, en },
    current: i18n.locale,
  },
})

Vue.use(Vuetify)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'md',
})

export default vuetify
