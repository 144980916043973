import { Session } from '@/models/Session'

/** Current session parameters */
let session: Session | undefined
/** Current secret key used for signing requests (WebCrypto format) */
let secretKey: CryptoKey | undefined

/**
 * Import secret key from session as WebCrypto format.
 */
export async function importSecretKey() {
  if (!session) throw new Error("Can't import secret key (no session exists)")
  const enc = new TextEncoder()
  // tslint:disable-next-line await-promise
  secretKey = await crypto.subtle.importKey(
    'raw', // Key is in RAW/Text format.
    enc.encode(session.secretKey), // Extract secret key from session.
    { name: 'HMAC', hash: 'SHA-512' }, // Use SHA-512 HMAC for signing.
    false, // This key is not extractable.
    ['sign'] // Use this key only for signing.
  )
  return secretKey
}

/**
 * Generate signature for request.
 * @param nonce - Timestamp in milliseconds.
 * @param body - Body contents as a string.
 */
export async function signRequest(nonce: number, body: string) {
  if (!secretKey)
    throw new Error("Can't sign request (no secret key available)")
  // Prepend nonce to body and encode
  const enc = new TextEncoder()
  const encoded = enc.encode(nonce + body)
  // Do the actual signing
  // tslint:disable-next-line await-promise
  const data = await crypto.subtle.sign('HMAC', secretKey, encoded)
  // Convert to HEX
  return Array.prototype.map
    .call(new Uint8Array(data), (x: number) =>
      ('00' + x.toString(16)).slice(-2)
    )
    .join('')
}

/**
 * Get session headers to append to a request for authentication purposes.
 * @param body - Request body.
 */
export async function getHeaders(body: string) {
  if (!session || !session.publicKey || !session.secretKey) return {}

  const nonce = Date.now()
  const signature = await signRequest(nonce, body)

  return {
    'X-ORION-NONCE': nonce,
    'X-ORION-PUBLICKEY': session.publicKey,
    'X-ORION-SIGNATURE': signature
  }
}

/**
 * Load session. Call this after logging in with the session body.
 * @param newSession - Session to load.
 */
export async function setSession(newSession: Session) {
  session = newSession
  return importSecretKey()
}

/**
 * Get the current session.
 */
export function getSession() {
  return session
}

/**
 * Clear the stored session. Call this after logging out.
 */
export function unsetSession() {
  session = undefined
  secretKey = undefined
}
