import store from '@/store'
import router from '@/router'

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (store.getters['auth/loggedIn']) {
      next()
      return
    }
    next({ name: 'login', query: { to: to.fullPath } })
  } else {
    next()
  }
})
