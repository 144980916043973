var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.schema.newDesign === true)?[_c('label',{staticStyle:{"color":"#8a8a8a","font-size":"14px"}},[_vm._v(_vm._s(_vm.schema.label || _vm.schema.editableLabel || _vm.name)+" ")]),(false)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("help ")])]}}],null,false,3383618807)},[_c('span',[_vm._v(_vm._s(_vm.$t('collection_field.title')))])]):_vm._e(),_c('div',{staticClass:"new-field-collection-field-select"},[_c('v-autocomplete',_vm._b({staticClass:"mt-1",class:_vm.schema.isDark === true ? 'is-dark' : '',attrs:{"color":"primary","dark":_vm.schema.isDark,"name":_vm.name,"hint":_vm.schema.description,"required":!_vm.schema.optional,"prepend-inner-icon":_vm.getIcon,"placeholder":_vm.fieldOptions.placeholder,"rules":[],"error-messages":_vm.errorMessages,"search-input":_vm.searchQuery,"disabled":_vm.disabled,"clearable":"","item-text":"name","multiple":_vm.fieldOptions.multi,"loading":_vm.$apollo.loading,"items":_vm.fields,"hide-details":"auto","outlined":"","dense":""},on:{"update:searchInput":function($event){_vm.searchQuery=$event},"update:search-input":function($event){_vm.searchQuery=$event}},scopedSlots:_vm._u([(_vm.fieldOptions.multi)?{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click:close":function($event){return _vm.remove(data.item.value)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}:{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name || data.item.title || data.item._id)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[(item.imgIcon)?_c('img',{attrs:{"alt":"","src":item.imgIcon}}):_vm._e(),_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name || item.title || item._id)}}),_c('v-list-item-subtitle',[_vm._l((item.labels),function(label,i){return _c('v-chip',{key:i,staticStyle:{"padding":"0 0.75em"},attrs:{"color":label.color,"text-color":label.textColor,"small":""},domProps:{"textContent":_vm._s(label.text)}})}),_vm._v(" "+_vm._s(item.subtitle)+" ")],2)],1)]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'v-autocomplete',_vm.fieldOptions,false))],1)]:[_c('v-autocomplete',_vm._b({attrs:{"prepend-icon":_vm.getIcon,"name":_vm.name,"label":_vm.schema.label || _vm.schema.editableLabel || _vm.name,"hint":_vm.schema.description,"required":!_vm.schema.optional,"placeholder":_vm.fieldOptions.placeholder,"rules":[],"error-messages":_vm.errorMessages,"search-input":_vm.searchQuery,"disabled":_vm.disabled,"clearable":"","item-text":"name","multiple":_vm.fieldOptions.multi,"loading":_vm.$apollo.loading,"items":_vm.fields,"hide-details":"auto"},on:{"update:searchInput":function($event){_vm.searchQuery=$event},"update:search-input":function($event){_vm.searchQuery=$event}},scopedSlots:_vm._u([(_vm.fieldOptions.multi)?{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"input-value":data.selected,"close":"","small":""},on:{"click:close":function($event){return _vm.remove(data.item.value)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}:{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name || data.item.title || data.item._id)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[(item.imgIcon)?_c('img',{attrs:{"alt":"","src":item.imgIcon}}):_vm._e(),_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name || item.title || item._id)}}),_c('v-list-item-subtitle',[_vm._l((item.labels),function(label,i){return _c('v-chip',{key:i,staticStyle:{"padding":"0 0.75em"},attrs:{"color":label.color,"text-color":label.textColor,"small":""},domProps:{"textContent":_vm._s(label.text)}})}),_vm._v(" "+_vm._s(item.subtitle)+" ")],2)],1)]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'v-autocomplete',_vm.fieldOptions,false))]],2)}
var staticRenderFns = []

export { render, staticRenderFns }