









































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { PaginatedQueryResult, FileManagerFile } from '@/models'
import { FileFragment } from './fragments'
import gql from 'graphql-tag'
import moment from '@/plugins/moment'
import Loading from '@/components/Loading.vue'
import _deburr from 'lodash/deburr'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    Loading
  },
  filters: {
    formatDate(input: string) {
      return moment(input).format('MM-DD-YYYY HH:mm')
    }
  }
})
export default class FileMultipleDownloadModal extends Vue {
  @Prop({ type: Boolean, required: true }) open!: boolean

  @Prop({ type: Array }) value?: FileManagerFile[]

  get modalOpen() {
    return this.open
  }

  set modalOpen(v: boolean) {
    this.$emit('update:open', v)
  }

  getUrl(file: FileManagerFile) {
    if (!file) return ''
    const { bucket, key } = file
    const endpoint =
      getEnv('VUE_APP_AWS_S3_ENDPOINT') || 'https://s3.amazonaws.com'
    return `${endpoint}/${bucket}/${key}`
  }

  get availableFiles() {
    if (!this.value) return []
    return this.value.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
  }
}
