export { default as Field } from './Field.vue'
export { default as View } from './View.vue'
export const Icon = 'attach_file'

export interface DocumentFieldOptions {
  collectionId?: string
  valueKey?: string
  labelKey?: string
  firstNameKey?: string
  lastNameKey?: string
  allowHsm?: boolean
  hsmRequire2fa?: boolean
  hsmClientId?: string
  hsmUserIdField?: string
  hsmUserId?: string
  placeholder?: string
  hsmReason?: string
  hsmBypass2faField?: string
}
