import { default as store, RootState } from '@/store/index'
import { Module } from 'vuex'

export interface SyncState {
  /** Tellus Active Conversation ID */
  tellusActiveConversationId?: string
}

const defaults = {
  tellusActiveConversationId: undefined
}

/**
 * Snackbar module - state of the global snackbar.
 */
const SyncModule: Module<SyncState, RootState> = {
  namespaced: true,
  state: {
    ...defaults
  },
  mutations: {
    setTellusActiveConversationId(state, value) {
      state.tellusActiveConversationId = value
    }
  }
}

export default SyncModule

window.addEventListener('storage', (event) => {
  if (event.key === 'tellusActiveConversationId') {
    store.commit('sync/setTellusActiveConversationId', event.newValue)
  }
})
