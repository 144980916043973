import { ComponentType, Chart } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const charts = {
  name: 'chart',
  namespace: 'charts',
  title: $t('charts_index.title'),
  titleSingle: $t('charts_index.titleSingle'),
  titleIsMale: true,
  description: $t('charts_index.description'),
  icon: 'insert_chart_outlined',
  tabIcon: 'insert_chart_outlined',
  color: 'indigo',
  overview:
    $t('charts_index.overview'),

  queryName: 'charts',
  queryFields: `{
    _id
    name
    title
    chartTypeId
  }`,
  searchable: true,

  transformResult: (chart) => ({
    ...chart,
    href: `charts/${chart._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
  list: () => asyncImport(import('./List.vue'))
} as ComponentType<Chart>
