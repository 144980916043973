



import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class StringFieldView extends Vue {
  @Prop({ type: String, default: '' }) value!: string
}
