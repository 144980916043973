var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"login"},[_c('v-main',[_c('v-container',{style:(_vm.bgStyle),attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-start":"","fill-height":""}},[(_vm.authenticating || _vm.$apollo.loading)?_c('v-flex',{staticClass:"acryllic-bg--themed login-container authenticating",attrs:{"xs12":""}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_c('v-card',{staticClass:"login-card",staticStyle:{"max-width":"480px"}},[(_vm.logo)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.logo}})]):_vm._e(),(_vm.loggedIn)?_c('v-card-text',{staticClass:"logged-in-greet text-center"},[_c('v-avatar',{attrs:{"size":64,"color":"grey lighten-4"}},[_c('img',{attrs:{"src":_vm.userAvatar,"alt":"avatar"}})]),_c('h2',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('ResetPassword.welcome'))+", "+_vm._s(_vm.user.profile.name || _vm.user.email)+"! ")]),_c('span',[_vm._v(_vm._s(_vm.$t('ResetPassword.redirect')))])],1):_vm._e(),_c('Loading')],1)],1)],1):_c('v-flex',{staticClass:"acryllic-bg--themed login-container",attrs:{"xs12":"","sm4":"","md3":""}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_c('v-card',{staticClass:"login-card"},[(_vm.logo)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.logo}})]):_vm._e(),(_vm.success)?_c('v-card-text',{staticClass:"text-center"},[_c('div',[_c('v-icon',{attrs:{"size":"48"}},[_vm._v("done")])],1),_c('div',[_vm._v(_vm._s(_vm.$t('ResetPassword.changedPass')))])]):(!_vm.loggedIn)?_c('v-card-text',[_c('Form',{ref:"resetForm",attrs:{"mutation":"resetPassword","submitButtonText":_vm.$t('ResetPassword.form.restartBtn'),"submitButtonIcon":"vpn_key","validationErrors":_vm.validationErrors,"schema":{
                    password: {
                      label: _vm.$t('ResetPassword.schema.password.label'),
                      type: 'string',
                      fieldType: 'password',
                      description: _vm.$t('ResetPassword.schema.password.description'),
                      fieldOptions: {
                        icon: 'lock',
                        min: 8
                      }
                    },
                    confirm: {
                      label: _vm.$t('ResetPassword.schema.confirm.label'),
                      type: 'string',
                      fieldType: 'password',
                      fieldOptions: {
                        icon: 'lock',
                        min: 8
                      }
                    }
                  },"no-reset":""},on:{"submit":_vm.onSubmit}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }