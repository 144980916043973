


















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import moment from '@/plugins/moment'

interface DateFieldOptions {
  previousDays?: number
  nextDays?: number
  min?: number
  max?: number
  integer?: boolean
  placeholder?: string
}

@Component({})
export default class DateField extends Vue {
  /** Current Value */
  @Prop({ type: String }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({type: Array, required: false}) rules!:Record<string, any>[]
  /** Rules disabled */
  @Prop({type: Boolean, default: false}) norules!: boolean
  /** Icon disabled */
  @Prop({type: Boolean, default: true}) noicon!: boolean
  /** outlined prop */
  @Prop({type: Boolean, default: true}) outlined!: boolean
  /** dense prop */
  @Prop({type: Boolean, default: true}) dense!: boolean

  open = false

  get getIcon(){
    if(this.noicon) return
    return 'event'
  }

  /** Validation Rules */
  get validationRules() {
    if(this.norules) return []
    const rules = this.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: number | null) => !!v || this.$t('date_field.script.validationRules.required'))
    }
    return rules
  }

  get prevDays() {
    if (
      this.fieldOptions.previousDays == null // | this.fieldOptions.previousDays < 0
    )
      return
    return moment
      .tz(new Date(), 'America/Santiago')
      .subtract(this.fieldOptions.previousDays, 'days')
      .toISOString()
  }

  get nextDays() {
    if (
      this.fieldOptions.nextDays == null // || this.fieldOptions.nextDays < 0
    )
      return
    return moment
      .tz(new Date(), 'America/Santiago')
      .add(this.fieldOptions.nextDays, 'days')
      .toISOString()
  }

  get dateValue() {
    return this.value ? moment(this.value).format('YYYY-MM-DD') : null
  }

  set dateValue(value: string | null) {
    this.$emit('input', value)
  }

  get displayValue() {
    return this.value ? moment(this.value).format('DD-MM-YYYY') : ''
  }

  /** Additional field options */
  get fieldOptions(): DateFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(value: string) {
    this.$emit('input', moment(value).utc().hour(12).toISOString())
    this.open = false
  }
}
