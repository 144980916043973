import { ComponentType, VueBlock } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import EditorLoading from './FullViewEdit/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const vueBlocks = {
  name: 'vueBlock',
  namespace: 'vueBlocks',
  title: $t('vueBlock_index.title'),
  titleSingle: $t('vueBlock_index.titleSingle'),
  description: $t('vueBlock_index.description'),
  icon: 'api',
  color: 'light-green',
  overview: $t('vueBlock_index.overview'),
  queryName: 'vueBlocks',
  queryFields: `{
    _id
    name
    title
  }`,
  searchable: true,
  titleIsMale: true,

  viewSkeleton: 'article',
  create: () => asyncImport(import('./Create.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
  fullViewEdit: () =>
    ({
      component: import('./FullViewEdit/FullViewEdit.vue').then(
        (e) => e.default
      ),
      loading: EditorLoading,
      error: ComponentError
    } as unknown as Vue.AsyncComponent)
} as ComponentType<VueBlock>
