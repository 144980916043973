import { ComponentType, Filter } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export const filters = {
  name: 'filter',
  namespace: 'filters',
  title: $t('filters_index.title'),
  titleSingle: $t('filters_index.titleSingle'),
  icon: 'filter_list',
  color: 'deep-orange',
  overview: $t('filters_index.overview'),

  queryName: 'filters',
  queryFields: `{
    _id
    name
    title
    collectionId
  }`,
  searchable: true,
  hidden: true,
  titleIsMale: true,

  transformResult: (filter) => ({
    ...filter,
    name: filter.name,
    title: filter.title,
    subtitle: filter.name,
    href: `filters/${filter._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Filter>
