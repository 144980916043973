





































import { Vue, Component, Prop } from 'vue-property-decorator'
import { getAvailableLocales } from '@/locales'

@Component({})
export default class LanguagePicker extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean

  get active() {
    return this.value
  }

  set active(value: boolean) {
    this.$emit('input', value)
  }

  locales = getAvailableLocales()

  save() {
    localStorage.locale = this.$root.$i18n.locale
    this.active = false
    window.location.reload()
  }
}
