






























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

@Component({})
export default class PromptDialog extends Vue {
  @Prop({ type: String }) title?: string
  @Prop({ type: String }) message?: string
  @Prop({ type: String, default: $t('dialogs_prompt.script.prop.done') })
  okButtonText?: string
  @Prop({ type: String, default: 'done' }) okButtonIcon?: string
  @Prop({ type: String, default: 'primary' }) okButtonColor?: string
  @Prop({ type: String, default: $t('dialogs_prompt.script.prop.cancel') })
  cancelButtonText?: string
  @Prop({ type: String, default: '' }) cancelButtonIcon?: string
  @Prop({ type: String, default: '' }) cancelButtonColor?: string
  @Prop({ type: String, default: '' }) placeholder?: string
  @Prop({ type: String, default: '' }) defaultValue?: string

  value = ''

/*   @Watch('defaultValue', { immediate: true })
  setDefaultValue(defaultValue: string) {
    this.value = defaultValue
  } */
}
