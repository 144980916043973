import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home/index.vue'
import Login from '@/views/Login.vue'
import SignUp from '@/views/SignUp.vue'
import NewLogin from '@/views/NewLogin.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import isAdminDomain from '@/utils/client/isAdminDomain'

Vue.use(Router)

let router: Router

if (isAdminDomain() && !window.mobileApp) {
  // Admin Router
  router = new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home
      },
      {
        path: '/login',
        name: 'login',
        component: NewLogin
      },
      {
        path: '/reset/:token',
        name: 'reset',
        props: true,
        component: ResetPassword
      },
      {
        path: '/about',
        name: 'about',
        component: () =>
          import(/* webpackChunkName: "about" */ './views/About.vue')
      },
      {
        path: '/test',
        name: 'test',
        component: () =>
          import(/* webpackChunkName: "test" */ './views/Test.vue')
      },
      {
        path: '/users/:userId',
        name: 'adminUser',
        props: true,
        meta: {
          requireAuth: true
        },
        component: () =>
          import(/* webpackChunkName: "adminUser" */ './views/User/index.vue')
      },
      {
        path: '/:environmentId',
        component: () =>
          import(/* webpackChunkName: "admin" */ './views/Admin/index.vue'),
        meta: {
          requireAuth: true
        },
        props: true,
        children: [
          {
            path: '/',
            name: 'admin',
            component: () =>
              import(
                /* webpackChunkName: "adminHome" */ './views/Admin/home.vue'
              ),
            meta: {
              requireAuth: true
            },
            props: true
          },
          {
            path: 'tutorials',
            name: 'adminTutorials',
            component: () =>
              import(
                /* webpackChunkName: "adminTutorials" */ './views/Admin/tutorials.vue'
              ),
            meta: {
              requireAuth: true
            },
            props: true
          },
          {
            path: 'collections',
            name: 'adminCollections',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "adminCollections" */ './views/Admin/Collections/index.vue'
              ),
            children: [
              {
                path: '/',
                name: 'adminCollectionIndex',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminCollectionIndex" */ './views/Admin/Collections/indexPlaceholder.vue'
                  )
              },
              {
                path: ':collectionId',
                name: 'adminCollectionEdit',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminCollectionEdit" */ './views/Admin/Collections/edit.vue'
                  )
              }
            ]
          },
          {
            path: 'design',
            name: 'adminDesign',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "adminDesign" */ './views/Admin/Design/index.vue'
              )
          },
          {
            path: 'design/:viewId',
            name: 'adminDesignEdit',
            props: true,
            meta: {
              darkNavbar: true
            },
            component: () =>
              import(
                /* webpackChunkName: "adminDesignEdit" */ './views/Admin/Design/edit.vue'
              )
          },
          {
            path: 'design-assistant/:viewId',
            name: 'adminDesignAssistant',
            props: true,
            meta: {
              darkNavbar: true
            },
            component: () =>
              import(
                /* webpackChunkName: "adminDesignAssistant" */ './views/Admin/Design/assistant.vue'
              )
          },
          {
            path: 'components/hooks',
            name: 'adminHookList',
            props: (r) => ({
              environmentId: r.params.environmentId,
              componentTypeName: 'hooks'
            }),
            component: () =>
              import(
                /* webpackChunkName: "adminHookEdit" */ './views/Admin/Components/list.vue'
              ),
            meta: {
              darkNavbar: true
            },
            children: [
              {
                path: '/',
                name: 'adminHookIndex',
                props: true,
                meta: {
                  darkNavbar: true
                },
                component: () =>
                  import('./views/Admin/Components/indexPlaceholder.vue')
              },
              {
                path: ':componentId',
                name: 'adminHookEdit',
                props: true,
                meta: {
                  darkNavbar: true
                },
                component: () => import('./views/Admin/Components/edit.vue')
              }
            ]
          },
          {
            path: 'components/tasks',
            name: 'adminTaskList',
            props: (r) => ({
              environmentId: r.params.environmentId,
              componentTypeName: 'tasks'
            }),
            component: () =>
              import(
                /* webpackChunkName: "adminTaskEdit" */ './views/Admin/Components/list.vue'
              ),
            meta: {
              darkNavbar: true
            },
            children: [
              {
                path: '/',
                name: 'adminTaskIndex',
                props: true,
                meta: {
                  darkNavbar: true
                },
                component: () =>
                  import('./views/Admin/Components/indexPlaceholder.vue')
              },
              {
                path: ':componentId',
                name: 'adminTaskEdit',
                props: true,
                meta: {
                  darkNavbar: true
                },
                component: () => import('./views/Admin/Components/edit.vue')
              }
            ]
          },
          {
            path: 'components/vueBlocks',
            name: 'adminVueBlockList',
            props: (r) => ({
              environmentId: r.params.environmentId,
              componentTypeName: 'vueBlocks'
            }),
            component: () =>
              import(
                /* webpackChunkName: "adminTaskEdit" */ './views/Admin/Components/list.vue'
              ),
            meta: {
              darkNavbar: true
            },
            children: [
              {
                path: '/',
                name: 'adminVueBlockIndex',
                props: true,
                meta: {
                  darkNavbar: true
                },
                component: () =>
                  import('./views/Admin/Components/indexPlaceholder.vue')
              },
              {
                path: ':componentId',
                name: 'adminVueBlockEdit',
                props: true,
                meta: {
                  darkNavbar: true
                },
                component: () => import('./views/Admin/Components/edit.vue')
              }
            ]
          },
          {
            path: 'components/api',
            name: 'adminApi',
            props: (r) => ({
              environmentId: r.params.environmentId,
              componentTypeName: 'apiEndpoints'
            }),
            component: () =>
              import(
                /* webpackChunkName: "adminApiEdit" */ './views/Admin/Components/list.vue'
              ),
            meta: {
              darkNavbar: true
            },
            children: [
              {
                path: '/',
                name: 'adminApiIndex',
                props: true,
                meta: {
                  darkNavbar: true
                },
                component: () =>
                  import('./views/Admin/Components/indexPlaceholder.vue')
              },
              {
                path: ':componentId',
                name: 'adminApiEdit',
                props: true,
                meta: {
                  darkNavbar: true
                },
                component: () => import('./views/Admin/Components/edit.vue')
              }
            ]
          },
          {
            path: 'components/:componentTypeName',
            name: 'adminComponentList',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "adminComponents" */ './views/Admin/Components/list.vue'
              ),
            children: [
              {
                path: '/',
                name: 'adminComponentIndex',
                props: true,
                component: () =>
                  import('./views/Admin/Components/indexPlaceholder.vue')
              },
              {
                path: ':componentId',
                name: 'adminComponentEdit',
                props: true,
                component: () => import('./views/Admin/Components/edit.vue')
              }
            ]
          },
          {
            path: 'settings',
            name: 'adminSettings',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "adminSettings" */ './views/Admin/Settings/index.vue'
              ),
            children: [
              {
                path: '',
                name: 'main',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsGeneral" */ './views/Admin/Settings/index.vue'
                  )
              },
              {
                path: 'general',
                name: 'adminSettingsGeneral',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsGeneral" */ './views/Admin/Settings/general.vue'
                  )
              },
              {
                path: 'appearance',
                name: 'adminSettingsAppearance',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsAppearance" */ './views/Admin/Settings/appearance.vue'
                  )
              },
              {
                path: 'users',
                name: 'adminSettingsUsers',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsUsers" */ './views/Admin/Settings/users.vue'
                  )
              },
              {
                path: 'profile',
                name: 'adminSettingsProfile',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsProfile" */ './views/Admin/Settings/profile.vue'
                  )
              },
              {
                path: 'env',
                name: 'adminSettingsEnv',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsProfile" */ './views/Admin/Settings/env.vue'
                  )
              },
              {
                path: 'componentBrowser',
                name: 'adminComponentBrowser',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsProfile" */ './views/Admin/Settings/componentBrowser.vue'
                  )
              },
              {
                path: 'roles',
                name: 'adminSettingsRoles',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsRoles" */ './views/Admin/Settings/roles.vue'
                  )
              },
              {
                path: 'endpoints',
                name: 'adminSettingsEndpoints',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsEndpoints" */ './views/Admin/Settings/endpoints.vue'
                  )
              },
              {
                path: 'navigation',
                name: 'adminSettingsNavigation',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "adminSettingsNavigation" */ './views/Admin/Settings/navigation.vue'
                  )
              }
            ]
          },
          {
            path: 'assistant',
            name: 'adminAssistant',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "adminAssistant" */ './views/Admin/Assistant/index.vue'
              )
          }
        ]
      }
    ]
  })
} else {
  router = new Router({
    mode: window.mobileApp ? 'hash' : 'history',
    routes: [
      {
        path: '/login',
        name: 'login',
        props: () => ({
          domain: window.environmentUrl || document.location.host
        }),
        component: Login
      },
      {
        path: '/signup',
        name: 'signup',
        props: () => ({
          domain: window.environmentUrl || document.location.host
        }),
        component: SignUp
      },
      {
        path: '/reset/:token',
        name: 'reset',
        props: true,
        component: ResetPassword
      },
      {
        path: '/',
        name: 'environment',
        component: () =>
          import(
            /* webpackChunkName: "environment" */ './views/Environment/index.vue'
          ),
        meta: {
          requireAuth: true
        },
        props: (route) => ({
          path: route.path,
          domain: window.environmentUrl || document.location.host
        }),
        children: [
          {
            path: 'profile',
            name: 'environmentUserProfile',
            component: () =>
              import(
                /* webpackChunkName: "environmentUserProfile" */ './views/Environment/Profile/index.vue'
              )
          },
          {
            path: 'manage',
            name: 'environmentManage',
            props: true,
            component: () =>
              import(
                 /* webpackChunkName: "environmentManage" */ './views/Environment/Layouts/LocalLayout.vue'
              ),
            children: [
              {
                path: '/',
                props: true,
                name: 'environmentManageHome',
                component: () =>
                  import(
                    /* webpackChunkName: "environmentManageHome" */ './views/Environment/Manage/Home.vue'
                  )
              },
              {
                path: 'users/:userId?',
                props: true,
                name: 'environmentManageUsers',
                component: () =>
                  import(
                    /* webpackChunkName: "environmentManageUsers" */ './views/Environment/Manage/Users.vue'
                  )
              },
              {
                path: 'tickets',
                props: true,
                name: 'environmentManageTickets',
                component: () =>
                  import(
                    /* webpackChunkName: "environmentManageTickets" */ './views/Environment/Manage/Tickets.vue'
                  )
              }
            ]
          },
          {
            path: '*',
            name: 'environmentView',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "environmentView" */ './views/Environment/Layouts/IndexLayout.vue'
              )
          }
        ]
      }
    ]
  })
}

export default router
