import { ComponentType, Hook } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import EditorLoading from './FullViewEdit/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'

export const hooks = {
  name: 'hook',
  namespace: 'hooks',
  title: 'Hooks',
  titleSingle: 'Hook',
  icon: 'memory',
  color: 'brown',
  overview:
    'Los Hooks son eventos personalizados que nos permitirán ejecutar acciones dentro de nuestro proyecto. Con CODELESS puedes crear tus propios eventos para cada acción que necesite tu proyecto.',

  queryName: 'hooks',
  queryFields: `{
    _id
    name
    functionTypeId
  }`,
  searchable: true,
  hidden: true,
  titleIsMale: true,

  transformResult: (hook) => ({
    ...hook,
    name: hook.name,
    title: hook.name,
    subtitle: hook.functionTypeId,
    icon: hook.functionTypeId === 'kraken' ? 'memory' : 'restore',
    labels:
      hook.functionTypeId === 'kraken'
        ? [{ text: 'v2.x', color: 'primary', textColor: 'white' }]
        : [{ text: 'v1.x' }],
    href: `hooks/${hook._id}`
  }),

  edit: () => asyncImport(import('./Edit.vue')),
  fullViewEdit: () =>
    ({
      component: import('./FullViewEdit/FullViewEdit.vue').then(
        (e) => e.default
      ),
      loading: EditorLoading,
      error: ComponentError
    } as unknown as Vue.AsyncComponent),
  list: () => asyncImport(import('./List.vue')),
  create: () => asyncImport(import('./Create.vue'))
} as ComponentType<Hook>
