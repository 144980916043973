













































































































































import { Vue, Component, Inject, InjectReactive } from 'vue-property-decorator'
import { User, Link, EnvironmentUser } from '@/models'
import gql from 'graphql-tag'
import _snakeCase from 'lodash/snakeCase'
import getEnv from '@/plugins/getEnv'
@Component({
  components: {},
  filters: {
    fixLegacyIcons(input: string) {
      if (input && input.indexOf('Md') === 0) {
        return _snakeCase(input.slice(2))
      }
      return input
    }
  },
  apollo: {
    userMenu: {
      query: gql`
        query ($environmentId: ID) {
          userMenu(environmentId: $environmentId)
        }
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class UserMenu extends Vue {
  userMenu: Link[] = []
  @InjectReactive() environmentUser!: EnvironmentUser | null
  noUser = false

  desktopApp = getEnv('VUE_APP_IS_DESKTOP')
  @Inject({ default: () => () => ({}) }) codelessRuntimeVars!: () => Record<
    string,
    any
  >
  async logout() {
    await this.$store.dispatch('auth/logout')
    await this.$router.push('/login')
    return
  }
  toggleDarkMode() {
    if (!this.environment?.allowDarkMode) return
    this.$store.commit('app/toggleDarkMode')
  }
  toggleEditLinks() {
    if (!this.isAdmin) return
    this.$store.commit('app/toggleEditLinks')
  }
  get adminLink() {
    if (window.mobileApp || this.editing || !this.environmentId) return ''
    if (getEnv('VUE_APP_ADMIN_DOMAIN')) {
      return `https://${getEnv('VUE_APP_ADMIN_DOMAIN')}/${this.environmentId}`
    }
  }
  get environment() {
    return this.$store.state.environment?.environment
  }
  get environmentId() {
    return this.environment?._id
  }
  get editing() {
    return this.codelessRuntimeVars()?.editing || false
  }
  get envProfile() {
    return this.user?.profile || {}
  }
  get user(): User {
    return this.$store.state.auth.user
  }
  get isAdmin() {
    return (
      this.user.roles.indexOf('admin') >= 0 ||
      this.user.roles.indexOf('superAdmin') >= 0
    )
  }
  get userAvatar(): string {
    return this.$store.getters['auth/userAvatarURL']
  }
  get userRoleDisplay(): string {
    if (!this.user) return ''
    if (this.user.roles.includes('superAdmin')) {
      return 'Súper Administrador'
    }
    if (this.user.roles.includes('admin')) {
      return 'Administrador'
    }
    return 'Usuario'
  }
  get canManageUsers() {
    if (this.desktopApp) return false
    if (this.isAdmin) return true
    if (!this.environmentUser) return false
    return (
      this.environmentUser.canAddUsers ||
      this.environmentUser.canRemoveUsers ||
      this.environmentUser.canEditProfiles ||
      this.environmentUser.canManageRoles
    )
  }
}
