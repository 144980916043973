




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import numeral from '@/plugins/numeral'

interface NumberFieldOptions {
  min?: number
  max?: number
  integer?: boolean
  placeholder?: string
}

@Component({})
export default class NumberField extends Vue {
  /** Current Value */
  @Prop({ type: Number }) value?: number | null
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({ type: Array, required: false }) rules!: Record<string, any>[]
  /** Rules disabled */
  @Prop({ type: Boolean, default: false }) norules!: boolean
  /** Icon disabled */
  @Prop({ type: Boolean, default: true }) noicon!: boolean
  /** outlined prop */
  @Prop({ type: Boolean, default: true }) outlined!: boolean
  /** dense prop */
  @Prop({ type: Boolean, default: true }) dense!: boolean

  get displayValue() {
    if (this.value == null) return ''
    return numeral(this.value).format('$0,0.[00]')
  }

  get getIcon() {
    if (this.noicon) return ''
    return 'attach_money'
  }
  /** Validation Rules */
  get validationRules() {
    if (this.norules) return []
    const rules = this.rules || []
    // Number validation
    rules.push(
      (v?: number) =>
        !v ||
        (numeral(v).value() && !isNaN(numeral(v).value())) ||
        this.$t('currency.script.validationRules.numeric')
    )
    // Required validation
    if (!this.schema.optional) {
      rules.push(
        (v?: number | null) =>
          !!v || this.$t('currency.script.validationRules.required')
      )
    }
    // Max number validation
    if (this.fieldOptions.max) {
      rules.push(
        (v?: number) =>
          numeral(v).value() <= this.fieldOptions.max! ||
          `${this.$t('currency.script.validationRules.exced')} ${
            this.fieldOptions.max
          }.`
      )
    }
    // Min number validation
    if (this.fieldOptions.min) {
      rules.push(
        (v?: number) =>
          numeral(v).value() >= this.fieldOptions.min! ||
          `${this.$t('currency.script.validationRules.under')} ${
            this.fieldOptions.min
          }.`
      )
    }
    // Integer validation
    if (this.fieldOptions.integer) {
      rules.push(
        (v?: number) =>
          numeral(v).value() % 1 === 0 ||
          this.$t('currency.script.validationRules.integer')
      )
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): NumberFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(value: string) {
    this.$emit('input', value !== '' ? numeral(value).value() : undefined)
  }
}
