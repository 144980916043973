import resolveLink from './resolveLink'
import router from '@/router'

/**
 * Resolves a link and opens it, either in the current window (web app) or a new one (mobile app)
 * @param link - The link to resolve
 * @param params - Params to pass to the link
 * @param newTab - Open the link in a new tab regardless of the current platform.
 */
export default async function openLink(
  link: string,
  params: Record<string, any>,
  newTab?: boolean
) {
  const resolvedLink = resolveLink(link, params)
  if (resolvedLink.external || newTab) {
    if (window.mobileApp || newTab) {
      window.open(resolvedLink.href)
    } else {
      window.location.href = resolvedLink.href
    }
  } else {
    return router.push(resolvedLink.href).catch(console.error)
  }
}
