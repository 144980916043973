var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"code-field"}},[_c('v-input',_vm._b({attrs:{"name":_vm.name,"messages":_vm.schema.description && [_vm.schema.description],"required":!_vm.schema.optional,"rules":_vm.validationRules,"error-messages":_vm.errorMessages,"disabled":_vm.disabled,"hide-details":"auto"}},'v-input',_vm.fieldOptions,false),[_c('v-layout',{staticStyle:{"flex-direction":"column"},attrs:{"wrap":""}},[_c('v-flex',{staticClass:"d-flex align-center",staticStyle:{"padding-bottom":"8px"}},[_c('v-icon',[_vm._v("polymer")]),_c('label',{staticStyle:{"margin-left":"8px"},domProps:{"textContent":_vm._s(_vm.schema.label || _vm.schema.editableLabel || _vm.name)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.monacoDialogOpen = true}}},[_c('v-icon',[_vm._v("launch")])],1)],1),(!_vm.fieldOptions.onlyMonaco)?_c('v-flex',[_c('CodeMirror',{ref:"cm",attrs:{"options":Object.assign({}, {mode: 'application/x-ejs',
            tabSize: 2,
            theme: 'material',
            readOnly: _vm.disabled,
            lineWrapping: true,
            lineNumbers: true,
            viewportMargin: _vm.fieldOptions.autoResize ? Infinity : 10},
            _vm.fieldOptions)},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}})],1):_vm._e()],1)],1),_c('monaco-dialog',{attrs:{"schema":_vm.schema,"disabled":_vm.disabled,"open":_vm.monacoDialogOpen,"environmentVariables":_vm.environmentVariables},on:{"update:open":function($event){_vm.monacoDialogOpen=$event}},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }