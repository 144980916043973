




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface PhoneFieldOptions {
  placeholder?: string
  noicon?: boolean
  icon?: string
  rules?: Record<string, any>
}

@Component({})
export default class PhoneField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Rules prop */
  @Prop({type: Array, required: false}) rules!:Record<string, any>[]
  /** Rules disabled */
  @Prop({type: Boolean, default: false}) norules!: boolean
  /** Icon disabled */
  @Prop({type: Boolean, default: false}) icon!: boolean
  /** outlined prop */
  @Prop({type: Boolean, default: true}) outlined!: boolean
  /** dense prop */
  @Prop({type: Boolean, default: true}) dense!: boolean

  get getIcon(){
    if(this.icon || this.fieldOptions.icon){
      return this.fieldOptions.icon || 'phone'
    }
    return ''
  }

  /** Validation Rules */
  get validationRules() {
    const rules = this.rules || this.fieldOptions.rules || []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('phone.script.validationRules.required'))
    }
    // Phone Validation
    // rules.push((v ?: string) => this.validateRut(v))
    return rules
  }

  /** Additional field options */
  get fieldOptions(): PhoneFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(...args: any[]) {
    this.$emit('input', ...args)
  }
}
