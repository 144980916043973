import gql from 'graphql-tag'

export const HookUpdateFragment = gql`
  fragment HookUpdate on Hook {
    name
    functionTypeId
    options
    validationsIds
    validationsMode
    ignoreErrors
    shouldThrow
  }
`

export const HookCreateFragment = gql`
  fragment HookCreate on Hook {
    _id
    environmentId
    createdAt
  }
`

export const HookFragment = gql`
  fragment Hook on Hook {
    ...HookCreate
    ...HookUpdate
  }

  ${HookCreateFragment}
  ${HookUpdateFragment}
`
